import React from "react";
import { getValue } from "../../../utils/lodash";
import { NavLink } from "react-router-dom";

function HomeStaticCard(props) {
  return (
    <>
      {getValue(props, `info.sameTab`, false) ? (
        <NavLink
          className="home-banner-quiz mt-4 cursor-pointer mb-4"
          to={`${getValue(props, `info.link`, "")}`}
        >
          <img
            src={`${getValue(props, `info.url`, "")}`}
            width={"100%"}
            height={"100%"}
          />
        </NavLink>
      ) : (
        <a
          className="home-banner-quiz mt-4 cursor-pointer mb-4"
          href={`${getValue(props, `info.link`, "")}`}
          target={"_blank"}
        >
          <img
            src={`${getValue(props, `info.url`, "")}`}
            width={"100%"}
            height={"100%"}
          />
        </a>
      )}
    </>
  );
}

export default HomeStaticCard;
