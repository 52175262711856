import React from "react";
import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import HealthiansLogo from "@assets/images/healthians/healthians-logo.svg";
import HealthiansBanner from "@assets/images/healthians/healthians-banner.webp";
import "./healthians.scss";
import BackSvgComponent from "../../assets/svg/back";
import { useNavigate } from "react-router-dom";
import LeadModal from "@components/lead-form";

const healthiansPackage = [
  {
    title: `Lipid profile`,
    included: "9",
    details: [
      "Cholesterol- Total, Serum",
      "Non-  HDL Cholesterol, Serum",
      "HDL Cholesterol Direct",
      "VLDL",
      "LDL Cholesterol- Direct",
      "LDL/HDL RATIO",
      "CHOL/HDL RATIO",
    ],
    discountPercentage: "50",
    actualPrice: "649",
    discountedPrice: "363",
  },
  {
    title: `Healthians Diabetic Checkup`,
    included: "44",
    details: [
      "Blood Glucose Fasting",
      "Lipid Profile Basic",
      "Kidney Function test",
      "HbA1c",
      "Urine Routine & Microscopy Extended",
    ],
    discountPercentage: "50",
    actualPrice: "1999",
    discountedPrice: "1119",
  },
  {
    title: `Random Blood Sugar`,
    included: "1",
    details: ["Random Blood Sugar"],
    actualPrice: "624",
    discountedPrice: "499",
  },
  {
    title: `Basic Allergy Package`,
    included: "25",
    details: ["IgE Total antibody", "COMPLETE BLOOD COUNT"],
    discountPercentage: "50",
    actualPrice: "1099",
    discountedPrice: "615",
  },
  {
    title: `Anaemia Package  `,
    included: "6",
    details: ["Folic Acid", "Vitamin B12 Cyanocobalamin", "Iron Studies"],
    discountPercentage: "50",
    actualPrice: "1299",
    discountedPrice: "727",
  },
  {
    title: `Culture & Sensitivity, Aerobic Bacteria, Urine  `,
    included: "1",
    details: ["Culture & Sensitivity"],
    actualPrice: "3999",
    discountedPrice: "1299",
  },
];

function Healthians() {
  const navigate = useNavigate();

  return (
    <div className="pt-3 pb-5" style={{ background: "#fff" }}>
      <div className="cursor-pointer mx-3" onClick={() => navigate("/")}>
        <BackSvgComponent />
      </div>
      <div className="d-flex justify-content-center">
        <img src={HealthiansLogo} className="healthians-logo" />
      </div>
      <div className="my-3">
        <img src={HealthiansBanner} className="healthians-banner w-100" />
      </div>
      <h6 className="healthians-heading">
        Full Body Checkup - Popular packages
      </h6>
      <div className="healthians-package-wrapper container">
        {healthiansPackage.map((packageItem, index) => (
          <div className="h-package-container" key={index}>
            <div>
              <h6 className="h-package-heading">{packageItem.title}</h6>
              <span className="h-package-includes">
                Includes {packageItem.included} Parameters
              </span>
              <ul className="h-package-details">
                {packageItem.details.map((detail, i) => (
                  <li key={i}>{detail}</li>
                ))}
              </ul>
            </div>
            <div>
              <div className="h-package-discount-wrapper">
                {packageItem.discountPercentage && (
                  <div className="h-package-discount-section">
                    <p className="h-package-upto-text">UPTO</p>
                    <p className="h-package-dicount-text">
                      {packageItem.discountPercentage}% OFF
                    </p>
                  </div>
                )}
                <div className="h-package-price-section">
                  <p className="h-package-offer-text">EXCLUSIVE OFFER</p>
                  <span className="h-package-upto-text h-package-strike-through">
                    ₹{packageItem.actualPrice}
                  </span>
                  <span className="h-package-dicount-text ps-2">
                    ₹{packageItem.discountedPrice}
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                {/* <button className="h-package-book-now">Book Now</button> */}
                <LeadModal
                  bookNowClass="h-package-book-now"
                  url="https://www.healthtunnel.com/healthians"
                  providerName="Healthians"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Healthians;
