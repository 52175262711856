import React from "react";
import { NavLink } from "react-router-dom";
import { getValue } from "../../utils/lodash";
import { config } from "../../env";
export default function ProviderCard(props) {
  const { info } = props;
  return (
    <div className="mt-4">
      {getValue(info, `providers.data.length`, 0) > 0 && (
        <h3
          className="order-medicine-wrapper__category-title text-uppercase order-medicine-wrapper__service-provider-title text-center mb-4"
          style={{
            backgroundImage: "url('/images/offers/rectangle-gradient.svg')",
            backgroundRepeat: "no-repeat",
          }}
        >
          {getValue(props, `title`, "")
            ? getValue(props, `title`, "")
            : getValue(info, `header_title`, "Providers")}
        </h3>
      )}
      <div className="custom-container">
        <div className="order-medicine-wrapper__category-grid">
          {getValue(props, "providers", []).map((eachElement, index) => {
            return (
              <a
                key={`providers-banner-${index}`}
                href={`${getValue(eachElement, `attributes.site_url`, "")}`}
                className="order-medicine-wrapper__link"
                target="_blank"
              >
                <img
                  src={`${config.MAIN_URL}${getValue(
                    eachElement,
                    `attributes.logo.data.attributes.url`,
                    ""
                  )}`}
                  key={`services${index}`}
                  className="img-fluid cursor-pointer order-medicine-wrapper__link-margin"
                />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}
