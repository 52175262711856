import React from "react";
import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import apolloLogo from "@assets/images/apollo/apollo-logo.png";
import apolloBanner from "@assets/images/apollo/apollo-banner.webp";
import "./apollo.scss";
import BackSvgComponent from "../../assets/svg/back";
import { useNavigate } from "react-router-dom";
import LeadModal from "@components/lead-form";

const apolloPackage = {
  "Top Booked Tests": [
    {
      title: "Vitamin-D Female",
      discountPercentage: "89",
      actualPrice: "2200",
      discountedPrice: "249",
    },
    {
      title: "Complete Blood Count (CBC)",
      testIncluded: "29",
      discountPercentage: "25",
      actualPrice: "513",
      discountedPrice: "385",
    },
    {
      title: "HbA1c, Glycated Hemogoblin",
      testIncluded: "3",
      discountPercentage: "25",
      actualPrice: "813",
      discountedPrice: "610",
    },
    {
      title: "Glucose, Fasting",
      testIncluded: "1",
      discountPercentage: "25",
      actualPrice: "107",
      discountedPrice: "80",
    },
    {
      title: "Lipid Profile",
      testIncluded: "8",
      discountPercentage: "25",
      actualPrice: "1067",
      discountedPrice: "800",
    },
    {
      title: "Liver Function Test(LFT)",
      testIncluded: "10",
      discountPercentage: "25",
      actualPrice: "1067",
      discountedPrice: "800",
    },
    // {
    //   title: "Glucose, Post Prandial (PP), 2 Hours (Post Meal)",
    //   testIncluded: "1",
    //   discountPercentage: "25",
    //   actualPrice: "107",
    //   discountedPrice: "80",
    // },
  ],
  "Popular Health Checkup Packages": [
    {
      title: "Apolo Health Check- Basix",
      testIncluded: "82",
      discountPercentage: "25",
      actualPrice: "1999",
      discountedPrice: "1499",
    },
    {
      title: "Apollo Thyroid Assessment- Basic",
      testIncluded: "32",
      discountPercentage: "25",
      actualPrice: "665",
      discountedPrice: "499",
    },
    {
      title: "Apollo Vitamin Check- Basic",
      testIncluded: "3",
      discountPercentage: "25",
      actualPrice: "1732",
      discountedPrice: "1299",
    },
    {
      title: "Apollo Diabetes Panel- Basic",
      testIncluded: "53",
      discountPercentage: "25",
      actualPrice: "1199",
      discountedPrice: "899",
    },
    {
      title: "Apollo Health Check- Master",
      testIncluded: "88",
      discountPercentage: "25",
      actualPrice: "3200",
      discountedPrice: "2400",
    },
    {
      title: "Xpert Health Annual Health Check-Up",
      testIncluded: "86",
      discountPercentage: "25",
      actualPrice: "2599",
      discountedPrice: "1949",
    },
  ],
};

function Apollo() {
  const navigate = useNavigate();

  return (
    <div className="pt-3 pb-5" style={{ background: "#fff" }}>
      <div className="cursor-pointer mx-3" onClick={() => navigate("/")}>
        <BackSvgComponent />
      </div>
      <div className="d-flex justify-content-center ">
        <img src={apolloLogo} className="apollo-logo mb-2" />
      </div>
      <div className="my-3">
        <img src={apolloBanner} className="apollo w-100" />
      </div>
      <div className=" container my-5">
        {Object.entries(apolloPackage).map(([heading, packages], index) => (
          <div key={index} className="mt-5">
            <h3 className="my-3">{heading}</h3>
            <div className="apollo-package-wrapper">
              {packages.map((packageItem, index) => (
                <div
                  key={index}
                  className="apollo-package-cards h-package-container "
                >
                  <div>
                    <h6 className="a-package-heading">{packageItem.title}</h6>
                    {packageItem.testIncluded && (
                      <span className="a-package-includes">
                        Tests Included: {packageItem.testIncluded}
                      </span>
                    )}
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <div className="d-flex gap-2 align-items-center">
                      <span className="a-package-price-text">
                        ₹{packageItem.discountedPrice}
                      </span>
                      <span className="a-package-actual-price">
                        {" "}
                        ₹{packageItem.actualPrice}
                      </span>
                      <span className="a-package-discount-text">
                        {packageItem.discountPercentage}% OFF
                      </span>
                    </div>
                    {/* <button className="a-book-now">Book Now</button> */}
                    <LeadModal
                      bookNowClass="a-book-now"
                      url="https://www.healthtunnel.com/apollo"
                      providerName="Apollo"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Apollo;
