import React, { useEffect, useState } from "react";
import VideoCard from "./components/video-card";
import "../../assets/scss/pages/videos/videos.scss";
import { getValue } from "../../utils/lodash";
import GoogleSiteVerification from "../../google-site-verification";
import { getAllVideos } from "../../services/APIs/common.service";
import { QueryRequestHelper } from "../../common/query-request-helper";
export default function VideosPage() {
  const [isVideoPopupVisible, setVideoPopupFlag] = useState(false);

  useEffect(() => {
    getVideos();
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getVideos = async () => {
    try {
      let payload = {
        populate: "cover_image",
        sort:'createdAt:DESC'
      };
      let queryRequest = QueryRequestHelper(payload);
      setIsLoading(true);
      let resp = await getAllVideos(queryRequest);
      if (resp) {
        setVideos(getValue(resp, "data", []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <section className="video-wrapper">
      <GoogleSiteVerification />

      <div className="custom-container">
        <div className="video-wrapper__header text-center">
          <h2 className="video-wrapper__title">Video Library</h2>
          <p className="video-wrapper__title-description">
            Exclusive health and fitness videos from medical experts and fitness
            professionals.
          </p>
        </div>
        {isLoading ? (
          "Please wait..."
        ) : videos.length > 0 ? (
          <div className="video-wrapper__body">
            {videos.map((tempObj, index) => {
              return (
                <VideoCard
                  videoPopupVisible={isVideoPopupVisible}
                  videocardObj={getValue(tempObj, `attributes`, {})}
                  showVideoDetailsPopup={() => {
                    setVideoPopupFlag(true);
                  }}
                  key={`video${index}`}
                />
              );
            })}
          </div>
        ) : (
          <p className="text-center">No Data Found</p>
        )}
      </div>
    </section>
  );
}
