import React from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import routes from "./routes/routes";
import Header from "./components/header";
import Footer from "./components/footer";
import HomePage from "./pages/Home";
import Pagenotfound from "./pages/page-not-found";
import TagManager from "react-gtm-module";

// Initialize GTM once when the module is loaded
const tagManagerArgs = {
  gtmId: "GTM-HZMLZ9PNLC", // Replace with your actual GTM ID
};

// Call the TagManager initialize function
TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <BrowserRouter>
      <Header showMenuItemsCTA={true} />
      <Routes>
        {routes.map((routeObj, index) => {
          const Element = routeObj.component;
          return (
            <Route
              key={index}
              path={routeObj.path}
              exact={routeObj.exact}
              element={<Element />}
            />
          );
        })}
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<Pagenotfound />} />
      </Routes>
      <ConditionalFooter />
    </BrowserRouter>
  );
}

const ConditionalFooter = () => {
  const location = useLocation();
  const isWeightLossPage = location.pathname === "/weight-loss";

  return <Footer weightlossFooter={isWeightLossPage} />;
};

export default App;
