export const blogs = [
  {
    id: 1,
    slug: "navigating-the-new-health-tunnel",
    image: "/images/blog/whats-new.webp",
    alt: "whats-new",
    heading: "Navigating the New HealthTunnel: A Comprehensive Guide",
  },
  {
    id: 3,
    slug: "burn-calories-fast-5-minute-weight-loss-challenge",
    image: "/images/blog/fat-burning.webp",
    alt: "fat-burning",
    heading: "Burn Calories Fast: 5-Minute Weight Loss Challenge",
  },
  {
    id: 2,
    slug: "embracing-the-spirit-of-Ramadan",
    image: "/images/blog/ramadan-dates.webp",
    alt: "embrasing-ramadan-with-dates",
    heading:
      "Embracing the Spirit of Ramadan: A Journey of Reflection, Renewal, and Ramadan Fasting Benefits",
  },
  {
    id: 4,
    slug: "truth-about-whey-protein-your-ultimate-health-tunnel-guide",
    image: "/images/blog/whey-protein.webp",
    alt: "whey-protein-scam-or-not",
    heading:
      "Truth about Whey Protein: Your Ultimate Health Tunnel Guide, Benefits, Usage, and Tips!",
  },
  {
    id: 5,
    slug: "what-is-hba1c",
    image: "/images/blog/understand-hba1c.webp",
    alt: "understanding-hba1c",
    heading: "What is HbA1c?",
  },
  {
    id: 6,
    slug: "how-long-does-it-take-to-lose-weight",
    image: "/images/blog/before-and-after-diet.webp",
    alt: "lady-before-and-after-diet",
    heading: "How Long Does It Take to Lose Weight?",
  },
  {
    id: 7,
    slug: "unravelling-the-Mystery-of-insomnia",
    image: "/images/blog/insomania.webp",
    alt: "lady-with-headache",
    heading:
      "Unravelling the Mystery of Insomnia: Understanding, Coping, and Conquering Sleeplessness",
  },
  {
    id: 8,
    slug: "guide-to-prevention-and-treatment-of-dengue",
    image: "/images/blog/doctor-with-thermometer.webp",
    alt: "doctor-with-thermometer",
    heading:
      "Surviving Dengue: A Comprehensive Guide to Prevention and Treatment",
  },
  {
    id: 9,
    slug: "hacks-to-keep-yourself-healthy-during-summers",
    image: "/images/blog/runner-woman-drinking-after-a-hard-race.webp",
    alt: "runner-woman-drinking-after-a-hard-race",
    heading:
      "Hacks to Keep Yourself Healthy During Summers",
  },
  {
    id: 10,
    slug: "key-benefits-of-a-vegan-diet-for-beginners",
    image: "/images/blog/vegan-diet.webp",
    alt: "vegan-diet",
    heading:
      "The Ultimate Guide to the Key Benefits of a Vegan Diet for Beginners",
  },
];
