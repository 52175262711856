import React from "react";

const HealthPackage = ({ planName, reportTime, parameter, details, discountedPrice, price, discountPercentage }) => (
  <div className="package-contianer mt-3">
    <div className="package-top-section d-flex justify-content-between row">
      <div className="plan-name col-7">
        <p>{planName}</p>
      </div>
      <div className="details-section col-5">
        <p>
          Reports in <span className="report">{reportTime}hrs</span>
        </p>
        <p>
          Parameters <span className="parameter">{parameter}</span>
        </p>
      </div>
    </div>
    <div className="package-middle-section">
      <p>
        {details}
      </p>
    </div>
    <div className="package-bottom-section d-flex">
      <div className="d-grid price-section">
        <p>₹ {discountedPrice}</p>
        <span>₹ {price}</span>
      </div>
      <div className="d-grid discount-section">
        <span>{discountPercentage}% off</span>
        <p>for a limited period</p>
      </div>
      <div>
        <a href="https://iframepartner.redcliffelabs.com/?pid=3p_Cn5MKekrHp6zJ3QsDH" className="book-button">
          Book
        </a>
      </div>
    </div>
  </div>
);

export default HealthPackage;
