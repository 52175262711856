import React from 'react';
import TitleLoder from './common-title-loader.js';
export default function OneStopLoader(props) {
  return (
    <>
      {props.isTitleVisble && <TitleLoder />}
      <div className="home-pagev2-wrapper__order-medicine-grid">
        <div className="home-pagev2-wrapper__order-medicine-box-loading animate-loader"></div>
        <div className="home-pagev2-wrapper__order-medicine-box-loading animate-loader"></div>
        <div className="home-pagev2-wrapper__order-medicine-box-loading animate-loader"></div>
        <div className="home-pagev2-wrapper__order-medicine-box-loading animate-loader"></div>
      </div>
    </>
  );
}
