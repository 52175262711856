import React from "react";
import "../assets/scss/pages/page-not-found.scss";
import { NavLink } from "react-router-dom";
import GoogleSiteVerification from "../google-site-verification";

export default class Pagenotfound extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="page-not-found-wrapper section-padding">
        <GoogleSiteVerification />
        <div className="custom-container d-flex justify-content-center align-items-center flex-direction-column-mobile gap-5">
          <img
            src="/images/404.webp"
            className="img-fluid not-found-image"
            alt="Error 404"
          />
          <div className="page-not-found-wrapper--meta ml-md-5">
            <h1>Error 404</h1>
            <p className="mt-1 mb-4">
              The page you are looking for doesn’t exists.<br /> We will take you
              back home.
            </p>
            <NavLink to="/" className="btn btn-primary">
              Home Page
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}
