import React from "react";
// import Phonesvg from '@assets/images/footerIcons/phone.svg';
import Mailsvg from "@assets/images/footerIcons/mail.svg";
import Addresssvg from "@assets/images/footerIcons/address.svg";
import { FormGroup, Label, Input } from "reactstrap";
import "@assets/scss/pages/contact-us/index.scss";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
// import fetchCreateEnquiry from '@/services/contact/fetchCreateEnquiry';
import { getValue } from "@utils/lodash";
import GoogleSiteVerification from "../google-site-verification";
import { createEnquiries } from "@services/APIs/common.service";

export default class AdvertiseWithUs extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();

    this.state = {
      name: "",
      email: "",
      phone: "",
      comments: "",
    };
  }
  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
  }
  handleSubmit = async () => {
    if (this.validator.allValid()) {
      try {
        let data = this.state.request;
        let resp = await createEnquiries({ data: data });
        if (resp) {
          toast.success("Submitted successfully");
          this.props.history.push(`/success`);
          this.reset();
          setTimeout(() => {
            this.validator.hideMessages();
            this.forceUpdate();
            window.location.reload();
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      this.validator.showMessages();
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.forceUpdate();
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      request: {
        ...prevState.request,
        [name]: value,
      },
    }));
  };
  reset = () => {
    this.setState({
      name: "",
      email: "",
      phone: "",
      comments: "",
    });
  };
  render() {
    return (
      <section className="contact-us-wrapper">
        <GoogleSiteVerification />
        <div
          className="contact-us-wrapper--banner-wrapper advertise-with-us section-padding"
          style={{
            backgroundImage: "url('/images/partner-wth-us/banner.png')",
          }}
        >
          <div className="custom-container d-flex flex-direction-column-mobile flex-direction-column-tablet position-relative">
            <div className="contact-us-wrapper--banner-wrapper--banner-meta">
              <div className="banner-header">
                <h1 className="mb-2 white-color">Advertise with us</h1>
                <h4 className="normal-font white-color">
                  Healthtunnel connects brands with the best audience in the
                  health sector. We have the following categories where you can
                  place your promotional advertisements in the mobile app and
                  the website.{" "}
                </h4>
                <br></br>
                <h4 className="normal-font white-color">
                  If you are interested in advertising with Healthtunnel, please
                  email us at advertise@healthtunnel.in with the details
                  including duration and format of the advertisement and we will
                  revert with the best package suitable
                </h4>
              </div>
            </div>
            <div className="partner-with-us-banner-wrapper--form-wrapper">
              <h3 className="normal-font mb-3">Connect with us</h3>
              {/* <Form> */}
              <FormGroup>
                <Label for="fullname">Full Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter Full Name"
                  value={getValue(this.state, "request.name", "")}
                  onChange={this.handleChange}
                />
                <div className="form-error">
                  {this.validator.message(
                    "Full Name",
                    getValue(this.state, "request.name", ""),
                    "required"
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="fullname">Email Address</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={getValue(this.state, "request.email", "")}
                  onChange={this.handleChange}
                  placeholder="Enter Email Address"
                />
                <div className="form-error">
                  {this.validator.message(
                    "Email",
                    getValue(this.state, "request.email", ""),

                    "required|email"
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="fullname">Phone Number</Label>
                <Input
                  type="number"
                  name="phone"
                  id="phone"
                  value={getValue(this.state, "request.phone", "")}
                  onChange={this.handleChange}
                  placeholder="Enter Phone Number"
                />
                <div className="form-error">
                  {this.validator.message(
                    "Phone Number",
                    getValue(this.state, "request.phone", ""),

                    "required|integer|size:10"
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="fullname">Comments</Label>
                <Input
                  type="textarea"
                  rows="3"
                  name="comments"
                  id="comments"
                  value={getValue(this.state, "request.comments", "")}
                  onChange={this.handleChange}
                  placeholder="Enter Comments"
                />
                <div className="form-error">
                  {this.validator.message(
                    "Comments",
                    getValue(this.state, "request.comments", ""),

                    "required"
                  )}
                </div>
              </FormGroup>
              <FormGroup className="mb-0 text-right">
                <button
                  className="theme-button theme-primary-btn bold-font"
                  onClick={this.handleSubmit}
                >
                  Submit Now
                </button>
              </FormGroup>
              {/* </Form> */}
            </div>
          </div>
        </div>
        <div className="contact-us-wrapper--contact-section mb-5">
          <div className="custom-container">
            <div className="banner-footer">
              <ul className="mt-4">
                {/* <li className="pb-2 d-flex align-items-center">
                  <img src={Phonesvg} className="mr-3" />
                  086390 05654
                </li> */}
                <li className="pb-2 d-flex align-items-center">
                  <img src={Mailsvg} className="mr-3" />
                  advertise@healthtunnel.in
                </li>
                <li className="d-flex align-items-start">
                  <img src={Addresssvg} className="mr-3" />
                  AABS Healthcare Pvt Ltd,
                  <br /> 6/c First floor, Outer ring road,Dodekankundi,
                  <br /> Bangalore-560037.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
