import React from "react";
import { getValue } from "../../utils/lodash";
import { config } from "../../env";
export default function Highlight3Card(props) {
  return (
    <>
      <div className="order-medicine-wrapper__category order-medicine-wrapper__category--bg-color inner-page-section-block-spacing">
      {getValue(props, "highlight.title", "") &&
        <h3
          className="order-medicine-wrapper__category-title mb-4 text-uppercase order-medicine-wrapper__service-provider-title text-center"
          style={{
            backgroundImage: "url('/images/offers/rectangle-gradiant-3.png')",
          }}
        >
          {getValue(props, "highlight.title", "")}
        </h3>}
        <div className="custom-container d-flex align-items-center justify-content-center">
          {getValue(props, "highlight.banners", []).map((item) => {
            return (
              <div className="text-center order-medicine-wrapper__highlight3">
                <a href={`${getValue(item, `link`, "")}`} target={`_blank`}>
                  <img
                    src={`${config.MAIN_URL}${getValue(
                      item,
                      `image.data.attributes.url`,
                      ""
                    )}`}
                    className="order-medicine-wrapper__highlight3-image img-fluid"
                    width="255"
                    height="166"
                  />
                  <p className="order-medicine-wrapper__highlight-title">
                    {getValue(item, "title", "")}
                  </p>
                </a>
              </div>
            );
          })}
          {/* <div className="text-center">
          <img
            src="/images/generic-icons/testimonial-slider-controls/image3highlight.png"
            className="img-fluid mr-3 ml-3 order-medicine-wrapper__highlight3-image"
            width="162"
          />
          <p className="order-medicine-wrapper__highlight-title">
            Sexual Wellness
          </p>
        </div>
        <div className="text-center">
          <img
            src="/images/generic-icons/testimonial-slider-controls/image2highlight.png"
            className="img-fluid order-medicine-wrapper__highlight3-image"
            width="162"
          />
          <p className="order-medicine-wrapper__highlight-title">
            Ortho Pain Relief
          </p>
        </div> */}
        </div>
      </div>
    </>
  );
}
