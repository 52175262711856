import React from "react";
import { getValue } from "../../../utils/lodash";
import CloseSVG from "../../../assets/svg/closeSVG";
export default function VideoPopup(props) {
  const video =
    getValue(props, "videoURL", "") &&
    getValue(props, "videoURL", "").split(
      "https://www.youtube.com/watch?v="
    )[1];
  const fVideos =
    !video &&
    getValue(props, "videoURL", "") &&
    getValue(props, "videoURL", "").split("https://youtu.be/")[1];
  const videos = !video
    ? !video && fVideos && fVideos.split("&t=")[0]
    : video && !fVideos && video.split("&t=")[0];
  return (
    <section
      className={
        props.showPopup
          ? "experience-wrapper__video-popup-wrapper active"
          : "experience-wrapper__video-popup-wrapper"
      }
    >
      <div className="custom-container m-auto">
        <div className="video-wrapper">
          <a
            className="cursor-pointer close-button"
            onClick={() => {
              props.hideVideoPopup();
            }}
          >
            {/* <img src="../../../assets/svg/closeSVG.js" className="img-fluid" alt="" /> */}
            <CloseSVG />
          </a>
          <iframe
            width="100%"
            height="400"
            src={`https://www.youtube.com/embed/${videos}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </section>
  );
}
