import React, { useEffect, useState } from "react";
import GoogleSiteVerification from "../../google-site-verification";
import { Link, useNavigate } from "react-router-dom";
import Facebooksvg from "../../assets/images/footerIcons/facebook.svg";
import Instagramsvg from "../../assets/images/footerIcons/instagram.svg";
import Youtubesvg from "../../assets/images/footerIcons/youtube.svg";
import Pinterest from "../../assets/images/footerIcons/pinterest.png";
import LinkedinSVG from "../../assets/images/footerIcons/linkedin.svg";
import Thread from "../../assets/images/footerIcons/thread.svg";
import Twittersvg from "../../assets/images/footerIcons/twitter.svg";
import Ticon from "../../assets/images/footerIcons/t-icon.png";
import TalkToExperts from "../../components/talk-to-experts/TalkToExperts";
import AllPrograms from "@components/all-programs/AllPrograms";
import BackSvgComponent from "@assets/svg/back";

function DiabetesReversal(props) {
  const navigate = useNavigate();

  const customerDp = [
    {
      user: "/images/wl/customer-dp-1.png",
      left: "0",
      zIndex: "4",
    },
    {
      user: "/images/wl/customer-dp-2.png",
      left: "36",
      zIndex: "3",
    },
    {
      user: "/images/wl/customer-dp-3.png",
      left: "72",
      zIndex: "2",
    },
    {
      user: "/images/wl/customer-dp-4.png",
      left: "108",
      zIndex: "1",
    },
  ];

  const programsButton = [
    "Nutritionist",
    "Fitness Coaches",
    "Dietitians",
    "Lifestyle Coaches",
  ];

  const programList = [
    {
      title: "Diabetic Reversal Program",
      description: [
        "Battling diabetes limitations? It’s time to fight back. Health Tunnel’s innovative reversal program empowers you to take control. Get personalized guidance and proven strategies to manage.  ",
        "Unlock a life free from restrictions and reclaim your well-being.",
        "Join Health Tunnel today!",
      ],
      image: "/images/wl/sugar_test.webp",
      background: "#CBE8F3",
    },
  ];

  const diabeticProblems = [
    {
      image: "/images/wl/medicine.webp",
      description: "Being on medication for rest of your life?",
    },
    {
      image: "/images/wl/weight-gain.webp",
      description: "Never being able to lose weight?",
    },
    {
      image: "/images/wl/tiredness.webp",
      description: "Having constant low energy levels",
    },
    {
      image: "/images/wl/diet.webp",
      description: "Staying on strict diets that you don't enjoy?",
    },
  ];

  return (
    <div className="home-pagev2-wrapper weight-loss-wrapper bg-white">
      <GoogleSiteVerification />

      <div className=" position-relative">
        <div
          className="cursor-pointer back-button bg-white"
          onClick={() => navigate(-1)}
        >
          <BackSvgComponent />
        </div>
        <div className="d-flex justify-content-center">
          <img src="/images/wl/banner-2.webp" className="banner-image" />
        </div>
      </div>
      {/*  <TalkToExperts selectedProgram="Diabetic reversal" /> */}
      <div className="custom-container pt-5">
        <h6 className="programs-heading text-center">Tailor Made Programs</h6>

        <div className="d-flex align-items-center justify-content-center mt-4 flex-wrap gap-2">
          <div className=" position-relative review-customers">
            {customerDp.map((images, index) => (
              <img
                src={images.user}
                style={{
                  left: `${images.left}px`,
                  zIndex: `${images.zIndex}`,
                }}
                key={index}
                width={56}
                height={56}
                className="top-0 position-absolute"
              />
            ))}
          </div>
          <div className="ms-4 pe-2">
            <span className="customers-count">2,100</span>
            <p className="bold-weight">Happy Customers</p>
          </div>
          <div className="ms-4 customer-reviews ps-4">
            <span className="customers-count">4.8/5</span>
            <div className="d-flex align-items-center gap-3">
              <div className="d-flex align-items-center gap-1">
                <img src="/images/wl/star.svg" />
                <img src="/images/wl/star.svg" />
                <img src="/images/wl/star.svg" />
                <img src="/images/wl/star.svg" />
                <img src="/images/wl/star.svg" />
              </div>
              <p className="bold-weight">Ratings</p>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center gap-3 mt-5 flex-wrap">
          {programsButton.map((program, index) => (
            <div key={index} className="program-buttons">
              {program}
              <img src="/images/wl/awesome.svg" />
            </div>
          ))}
        </div>
        <div className=" container my-5 pb-5">
          {programList.map((program, index) => (
            <div key={index} className="program-details row gap-3">
              <div className="col-12 col-lg-6 p-0">
                <img src={program.image} className="program-image " />
              </div>
              <div className="col-12 col-lg-5 ms-lg-5 p-0 mt-4 mt-lg-0">
                <h4 className="program-title">{program.title}</h4>
                {program.description.map((desc, idx) => (
                  <p key={idx} className="mt-3 text-black text__20">
                    {desc}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <TalkToExperts selectedProgram="Diabetic reversal" />
      <div className="custom-container py-5">
        <h6 className="programs-heading text-center">
          The Ideal Reversal Diabetes Diet Plan
        </h6>
        <p className="program-description mt-3 text-center">
          Does being diabetic make you worry about...
        </p>
        <div className="d-flex justify-content-center pb-3 align-items-baseline mt-5 diabetics-wrapper">
          <div className="diabetics-container">
            {diabeticProblems.map((details, index) => (
              <div className="diabetic-cards p-4 gap-3" key={index}>
                <img src={details.image} width={60} height={60} />
                <p className="text-black">{details.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <TalkToExperts selectedProgram="Diabetic reversal" />
      <AllPrograms showWeightloss={true} showPcos={true} showYoga={true} />
      <div className="footer-wrapper--header d-flex align-items-center justify-content-between flex-direction-column-mobile mt-4 custom-container footer-border pb-4 pt-2">
        <div className="icon-logo d-flex  align-items-center gap-md-5 flex-column flex-md-row gap-2">
          <p className="mb-3">Follow us</p>
          <ul>
            <li>
              <a href="https://twitter.com/HealthTunnel" target="_blank">
                <img src={Twittersvg} />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/Healthtunnel/" target="_blank">
                <img src={Facebooksvg} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/healthtunnelapp/"
                target="_blank"
              >
                <img src={Instagramsvg} />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCIrAknx2mNOIohWD23cpE4w"
                target="_blank"
              >
                <img src={Youtubesvg} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://in.pinterest.com/HealthTunnel/_created"
              >
                <img src={Pinterest} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/health-tunnel"
              >
                <img src={LinkedinSVG} />
              </a>
            </li>
            <li>
              <a target="_blank" href="http://healthtunnel.tumblr.com/">
                <img src={Ticon} />
              </a>
            </li>
            <li>
              <a target="_blank" href="http://healthtunnel.tumblr.com/">
                <img src={Thread} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DiabetesReversal;
