import React, { useState } from "react";
import { getValue } from "../../../utils/lodash";
import VideoPopup from "./video-popup";
import { config } from "../../../env";

export default function VideoCard(props) {
  const [isVideoPopupVisible, setVideoPopupFlag] = useState(false);
  return (
    <>
      {/* <Link href={`/video-guide/${props.videocardObj.id}`}> */}
      <a herf="#0" className="video-card-wrapper">
        <div className="video-card-wrapper__image-play">
          <div className="position-relative">
            <img
              src={`${config.MAIN_URL}${getValue(
                props,
                `videocardObj.cover_image.data.attributes.url`
              )}`}
              className="img-fluid video-card-wrapper__video-image"
            />
            <div className="video-card-wrapper__image-play--position">
              <img
                src="/images/video-image/play-arrow.svg"
                className="img-fluid video-card-wrapper__play-img-style"
              />
              <div
                className="video-card-wrapper__image-play--position"
                onClick={() => {
                  setVideoPopupFlag(true);
                }}
              >
                <img
                  src="/images/video-image/play-arrow.svg"
                  className="img-fluid video-card-wrapper__play-img-style"
                />
                <p className="video-card-wrapper__play-vdo">Play Video</p>
              </div>
            </div>
          </div>
          <div className="video-card-wrapper__details">
            <p className="video-card-wrapper__description">
              {props.videocardObj.description}
            </p>
          </div>
        </div>
      </a>
      {/* </Link> */}
      <VideoPopup
        showPopup={isVideoPopupVisible}
        videoURL={getValue(props, `videocardObj.url`)}
        hideVideoPopup={() => {
          setVideoPopupFlag(false);
        }}
      />
    </>
  );
}
