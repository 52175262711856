import React from "react";

const WhyChoose = ({ icon, text }) => (
  <div className="whychoose-contianer">
    <div className="d-flex align-items-center">
      {/* <FontAwesomeIcon icon={icon} /> */}
      <img src={icon} className="icon-container" />
      <a href="https://iframepartner.redcliffelabs.com/?pid=3p_Cn5MKekrHp6zJ3QsDH">
        {text}
      </a>
    </div>
  </div>
);

export default WhyChoose;
