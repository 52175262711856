import React, { useState, useRef, useEffect } from "react";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import { getValue } from "@utils/lodash";
import SimpleReactValidator from "simple-react-validator";
// import { useNavigate, useParams } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import "./lead-form.scss";
import { IoClose } from "react-icons/io5";
import { createLeadForm } from "@services/APIs/common.service";

const LeadModal = ({ bookNowClass, url, providerName }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  // const navigate = useNavigate();
  // const params = useParams();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const [request, setRequest] = useState({
    name: "",
    phone: "",
    email: "",
    city: "",
    postal_code: "",
    url: url || "",
    provider_name: providerName || "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmitForm = async (close) => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      try {
        setIsLoading(true);
        let resp = await createLeadForm({ data: request });
        if (resp) {
          setRequest({
            ...request,
            name: "",
            phone: "",
            email: "",
            city: "",
            postal_code: "",
            url: url || "",
            provider_name: providerName || "",
          });
          toast.success("Submitted successfully");
          console.log(request);
          setIsLoading(false);
          close();
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  return (
    <Popup
      trigger={<button className={bookNowClass}> Book Now </button>}
      modal
      open={open}
      onClose={() => setOpen(false)}
      className="lead-form"
    >
      {(close) => (
        <div className="w-100">
          <button className="lead-close-button" onClick={close}>
            <IoClose size={20} />
          </button>
          <h5>
            Get a call back from our <br />
            Service Provider
          </h5>
          <div className="d-flex justify-content-center w-100">
            <div className="mt-3 w-100">
              <div className=" mb-3">
                <div className="lead-form-wrapper">
                  <label className="lead-label">Name *</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    className="lead-input"
                    value={getValue(request, "name", "")}
                    name="name"
                    onChange={handleChange}
                    id="name"
                  />
                </div>
                <span className="form-error add-margin-bottom-20">
                  {simpleValidator.current.message(
                    "Full Name",
                    getValue(request, `name`, ""),
                    "required"
                  )}
                </span>
              </div>
              <div className=" mb-3">
                <div className="lead-form-wrapper">
                  <label className="lead-label">Phone Number *</label>
                  <input
                    type="number"
                    placeholder="Enter Phone Number"
                    className="lead-input"
                    value={getValue(request, "phone", "")}
                    name="phone"
                    onChange={handleChange}
                    id="mob-no"
                  />
                </div>
                {
                  <span className="form-error add-margin-bottom-20">
                    {simpleValidator.current.message(
                      "Mobile Number",
                      getValue(request, `phone`, ""),
                      "required|integer|size:10"
                    )}
                  </span>
                }
              </div>
              <div className=" mb-3">
                <div className="lead-form-wrapper">
                  <label className="lead-label">Email Address *</label>
                  <input
                    type="email"
                    placeholder="Enter Email Address"
                    className="lead-input"
                    value={getValue(request, "email", "")}
                    name="email"
                    onChange={handleChange}
                    id="email"
                  />
                </div>
                {
                  <span className="form-error">
                    {simpleValidator.current.message(
                      "Email",
                      getValue(request, `email`, ""),
                      "required"
                    )}
                  </span>
                }
              </div>
              <div className=" mb-3">
                <div className="lead-form-wrapper">
                  <label className="lead-label">City</label>
                  <input
                    type="text"
                    placeholder="Enter City"
                    className="lead-input"
                    value={getValue(request, "city", "")}
                    name="city"
                    onChange={handleChange}
                    id="city"
                  />
                </div>
              </div>
              <div className=" mb-5">
                <div className="lead-form-wrapper">
                  <label className="lead-label">Pincode</label>
                  <input
                    type="number"
                    placeholder="Enter Pincode"
                    className="lead-input"
                    // onInput={(e) => {
                    //   e.target.value = Math.max(0, parseInt(e.target.value))
                    //     .toString()
                    //     .slice(0, 6);
                    // }}
                    value={getValue(request, "postal_code", "")}
                    name="postal_code"
                    onChange={handleChange}
                    id="postal_code"
                  />
                </div>
              </div>
              <button
                className={`${bookNowClass} m-0 w-100`}
                onClick={() => handleSubmitForm(close)}
                disabled={isLoading}
              >
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default LeadModal;
