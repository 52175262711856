import React from 'react';
import Bannersection from '@components/partner-with-us/banner';
import Whypartnerwithus from '@components/partner-with-us/why-partner-us';
import Testimonial from '@components/partner-with-us/testimonial';
import { getValue } from '@utils/lodash';
import SimpleReactValidator from 'simple-react-validator';
// import fetchCreatePartnerEnquiry from '@/services/contact/fetchCreatePartnerEnquiry';
import { toast } from 'react-toastify';
import GoogleSiteVerification from '../google-site-verification';
import { createPartnerEnquiries } from '@services/APIs/common.service';

export default class PartnerWithUs extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      testimonialObj: {
        testimonialDetails:
          '“This colloboration never could have been so successful without the extraordinary cooperation and relationship with VLCC and Healthtunnel. From day one, it felt like we were one team, and that camaraderie grew.”<br></br><br></br>“From the initial stage in the marketing to conversion we saw getting the right users and overall 60% improvement to overall plan of acquiring customers”',
        name: 'Raman Reddy',
        job_title: 'HERB',
        personImage: '/images/testimonial/testimonial.png',
        backgroundColor: '#FEBA2C'
      },
      request: {
        name: '',
        company_name: '',
        email: '',
        phone: '',
        comments: ''
      }
    };
  }
  componentDidMount(){
    window.scroll({ top: 0, behavior: "smooth" });
  }
  reset = () => {
    this.setState(prevState=>({
      request:{
        ...prevState.request,
        name: '',
        company_name: '',
        email: '',
        phone: '',
        comments: ''
      }

      }))
    }
  handleSubmit = async () => {
    if (this.validator.allValid()) {
      try {
        let data = this.state.request;
        let resp = await createPartnerEnquiries({data:data});
        if (resp) {
          toast.success('Submitted successfully');
          this.reset();
          setTimeout(()=>{
            this.validator.hideMessages();
            this.forceUpdate();
            window.location.reload()
          },1000)
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      this.validator.showMessages();
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.forceUpdate();
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      request: {
        ...prevState.request,
        [name]: value
      }
    }));
  };
  render() {
    return (
      <div className="partner-with-us-wrapper">
        <GoogleSiteVerification />
        <Bannersection
          request={getValue(this.state, 'request', {})}
          validator={this.validator}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
        />
        <Whypartnerwithus />
        <Testimonial testimonialObj={this.state.testimonialObj} />
      </div>
    );
  }
}
