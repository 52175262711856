import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BookedItem = ({ icon, text }) => (
  <div className="billed-contianer">
    <div className="icon-wrapper">
      {/* <FontAwesomeIcon icon={icon} /> */}
      {icon} 
    </div>
    <a href="https://iframepartner.redcliffelabs.com/?pid=3p_Cn5MKekrHp6zJ3QsDH">{text}</a>
  </div>
);

export default BookedItem;
