import React from "react";
import { getValue } from "../../utils/lodash";
import { config } from "../../env";
export default function ProductCard(props) {
  return (
    <>
      <h3
        className="order-medicine-wrapper__category-title mb-4 text-uppercase order-medicine-wrapper__service-provider-title text-center"
        style={{
          backgroundImage: "url('/images/offers/rectangle-gradiant-3.png')",
        }}
      >
        {getValue(props, `info.product_title`, "Product Store")}
      </h3>
      <div className="custom-container">
        <div className="order-medicine-wrapper__grid mb-4">
          {getValue(props, "products", []).map((main) => {
            let item = getValue(main, `attributes`, {});
            return (
              <div className="order-medicine-wrapper__store-details position-relative">
                <a
                  href={`${getValue(item, `url`, "")}`}
                  target={getValue(item, `url`, "") ? "blank" : ""}
                >
                  <img
                    src={`${config.MAIN_URL}${getValue(
                      item,
                      `cover_image.data.attributes.url`,
                      ""
                    )}`}
                    className="img-fluid order-medicine-wrapper__store-details-image w-100"
                  />
                  <div className="order-medicine-wrapper__border-store ">
                    <h5 className="order-medicine-wrapper__store-details-title">
                      {getValue(item, "title", "")}
                    </h5>

                    <p
                      className="product-reward-wrapper__text order-medicine-wrapper__store-details-text normal-font"
                      dangerouslySetInnerHTML={{
                        __html: getValue(item, "description", ""),
                        // .substring(0, 150)
                        // .concat(' ...')
                      }}
                    />

                    <h4 className="order-medicine-wrapper__store-details-text--font">
                      <span>INR {getValue(item, "original_price", "")}</span>INR{" "}
                      {getValue(item, "selling_price", "")}
                    </h4>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
