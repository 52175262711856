import { config } from "../../env";
import { Delete, get, patch, post } from "../helpers/http-handler";
// import config from "../config";

export const getAllTestimonials = (queryRequest) =>
  get(`${config.API_URL}/weight-loss-testimonals?${queryRequest}`);

export const getSpecificConsulationForm = () =>
  get(`${config.API_URL}/consultation-forms/`);

export const createConsulationForm = (payload) =>
  post(`${config.API_URL}/consultation-forms`, payload);

export const createConsulationPayment = (payload) =>
  post(`${config.API_URL}/consultation-form-payments`, payload);

export const createLeadForm = (payload) =>
  post(`${config.API_URL}/lead-forms`, payload);

export const createEnquiries = (payload) =>
  post(`${config.API_URL}/enquiries`, payload);

export const createPartnerEnquiries = (payload) =>
  post(`${config.API_URL}/partner-enquiries`, payload);

export const getPages = (queryRequest) =>
  get(`${config.API_URL}/pages?${queryRequest}`);

export const getAllVideos = (queryRequest) =>
  get(`${config.API_URL}/videos?${queryRequest}`);
