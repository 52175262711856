import React from "react";
import { getValue } from "../../utils/lodash";
import { config } from "../../env";
import { NavLink, useNavigate, useParams } from "react-router-dom";

function SubCategoryCard(props) {
  const params = useParams();
  return (
    <>
      <div className="custom-container mt-4">
        <div className="order-medicine-wrapper__category-view">
          {getValue(props, "subCategories", []).map((eachElement, index) => {
            console.log(eachElement);
            return (
              <>
                <NavLink
                  key={`providers-banner-${index}`}
                  to={`/sub-category-detail/${getValue(
                    params,
                    `slug`,
                    ""
                  )}/${getValue(eachElement, "id", "")}`}
                  className="subcategory-wrapper__link"
                >
                  <img
                    src={`${config.MAIN_URL}${getValue(
                      eachElement,
                      `attributes.web_image.data.attributes.url`,
                      ""
                    )}`}
                    key={`services${index}`}
                    className="img-fluid cursor-pointer subcategory-wrapper__link-margin"
                  />
                  <p className="order-medicine-wrapper__highlight-title">
                    {getValue(eachElement, "attributes.name", "")}
                  </p>
                </NavLink>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default SubCategoryCard;
