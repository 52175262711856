import React from  'react';
import "@assets/scss/pages/partner-with-us/why-partner-us.scss";

export default class PartnerWithUs extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        whyPartnerUsArray: [{
          title: 'Unlock New Business Opportunities',
          image: '/images/partner-with-us/why-us/business.svg',
          description: 'Thousands of Healthtunnel users may be searching for healthcare solutions in your area. By partnering, we can help you reach those users.'
        },
        {
          title: 'Build Trust and Drive Business',
          image: '/images/partner-with-us/why-us/trust.svg',
          description: 'Stand out with Healthtunnel marketing to reach even more customers and increase sales. Our offerings are flexible so you can customise them to your needs.'
        },
        {
          title: 'Single platform on Healthcare',
          image: '/images/partner-with-us/why-us/platform.svg',
          description: 'We put our expertise at the service of our partners. Together we can help to improve healthcare services and access to care for those in need.'
        }]
      }
    }
    render() {
        const {whyPartnerUsArray} = this.state;
        return (
            <section className="why-partner-with-us-wrapper section-padding text-center">
                <div className="custom-container">
                    <h2 className="mb-4">Why Partner with us?</h2>
                    <div className="why-partner-with-us-wrapper--grid">
                        {whyPartnerUsArray.map((whyusObj, index) => {
                            return (
                                <div className="why-partner-withus" key={index}>
                                    <img src={whyusObj.image} className="fluid-img" alt={whyusObj.title} width="105" />
                                    <h3 className="normal-font">{whyusObj.title}</h3>
                                    <p className="light-font">{whyusObj.description}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        )
    }
}