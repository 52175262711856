import { getValue } from "../../../utils/lodash";

function ConsultationForm({
  request,
  handleChange,
  simpleValidator,
  genderEnum,
  setRequest,
  programEnum,
  handleSubmitForm,
  isLoading,
}) {
  return (
    <div>
      <div className="d-flex">
        <div className="form-group mb-3 w-50">
          <div>
            <label className="mb-1" htmlFor="full-name">
              Full Name *
            </label>
            <input
              type="text"
              value={getValue(request, "name", "")}
              name="name"
              onChange={handleChange}
              className="form-control lab-text-contact-form-wrapper__input lab-text-contact-form-wrapper__input--spacing"
              placeholder="Enter Full Name"
              id="name"
            />
          </div>
          {
            <span className="form-error">
              {simpleValidator.current.message(
                "Full Name",
                getValue(request, `name`, ""),
                "required"
              )}
            </span>
          }
        </div>
        <div className="mx-2"></div>
        <div className="form-group mb-3 w-50">
          <label className="mb-1" htmlFor="mob-no">
            Mobile Number *
          </label>
          <div className="lab-text-contact-form-wrapper__contact-form-position">
            <input
              value={getValue(request, "phone", "")}
              name="phone"
              onChange={handleChange}
              type="number"
              className="form-control lab-text-contact-form-wrapper__input"
              placeholder="Enter Mobile Number"
              id="mob-no"
            />
            <p className="lab-text-contact-form-wrapper__contact-form-position lab-text-contact-form-wrapper__contact-form-position--absolute">
              +91
            </p>
          </div>
          {
            <span className="form-error">
              {simpleValidator.current.message(
                "Mobile Number",
                getValue(request, `phone`, ""),
                "required|integer|size:10"
              )}
            </span>
          }
        </div>
      </div>
      <div className="d-flex">
        <div className="form-group mb-3 w-50">
          <label className="mb-1" htmlFor="full-name">
            Email *
          </label>
          <input
            type="text"
            value={getValue(request, "email", "")}
            name="email"
            onChange={handleChange}
            className="form-control lab-text-contact-form-wrapper__input lab-text-contact-form-wrapper__input--spacing"
            placeholder="Enter Email Address"
            id="email"
            required
          />
          {
            <span className="form-error">
              {simpleValidator.current.message(
                "Email",
                getValue(request, `email`, ""),
                "required"
              )}
            </span>
          }
        </div>
        <div className="mx-2"></div>
        <div className="form-group mb-3 w-50">
          <label className="mb-1" htmlFor="full-name">
            Gender
          </label>
          <div className="d-flex">
            {genderEnum.map((item) => {
              return (
                <div
                  className={`form-container cursor-pointer bg-white ${
                    getValue(request, `gender`, "") === item ? "active" : ""
                  }`}
                  onClick={() =>
                    setRequest({
                      ...request,
                      gender: item,
                    })
                  }
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="border-bottom mt-5 mb-3" />
      <h4 className="lab-text-contact-form-wrapper__contact-form-title mb-3">
        Which program do you opt for?
      </h4>
      <div className="form-group mb-5 mt-3">
        <div className="d-flex justify-content-center align-items-center w-100">
          {programEnum.map((item) => {
            return (
              <div
                className={`form-container cursor-pointer bg-white ${
                  getValue(request, `program`, "") === item ? "active" : ""
                }`}
                onClick={() =>
                  setRequest({
                    ...request,
                    program: item,
                  })
                }
              >
                {item}
              </div>
            );
          })}
        </div>
      </div>
      <div className="border-bottom mt-3 mb-3" />

      <h4 className="lab-text-contact-form-wrapper__contact-form-title mb-3">
        Select your consultation type
      </h4>
      <div className="form-group mb-3 ">
        <div className="d-flex justify-content-center align-items-center w-100">
          <div
            className={`form-container cursor-pointer bg-white ${
              getValue(request, `is_paid`, "") === false ? "active" : ""
            }`}
            onClick={() =>
              setRequest({
                ...request,
                is_paid: false,
              })
            }
          >
            {/* {getValue(request, `is_paid`, "")  */}
            Schedule an appointment through our customer service agent's
          </div>
          <div
            className={`form-container cursor-pointer bg-white ${
              getValue(request, `is_paid`, "") === true ? "active" : ""
            }`}
            onClick={() =>
              setRequest({
                ...request,
                is_paid: true,
              })
            }
          >
            Book an introductory consultation with Nutritionist for just INR 99
          </div>
        </div>
      </div>
      <div className="text-center">
        <button
          className="theme-button w-50 theme-primary-button mb-3 mt-4"
          onClick={handleSubmitForm}
          disabled={isLoading === true}
        >
          {isLoading ? "Submitting" : "Submit"}
        </button>
      </div>
    </div>
  );
}

export default ConsultationForm;
