import React from 'react';
import Testimonials from '../components/home-page/testimonials/index.js';
import '../assets/scss/pages/about-us-page/about-us.scss';
import Ourcorevalues from '@components/about-us/our-core-values';
import GoogleSiteVerification from '../google-site-verification';

export default class AboutUs extends React.Component {
  componentDidMount(){
    window.scroll({ top: 0, behavior: "smooth" });
  }
  render() {
    return (
      <section className="about-page-wrapper">
        <GoogleSiteVerification />
        <div className="about-us-wrapper">
          <div
            className="about-us-wrapper--image position-relative"
            style={{ backgroundImage: `url('/images/about-us/aboutus.png')` }}
          >
            <div className="custom-container about-us-header-container position-absolute">
              <h3>About Us</h3>
            </div>
          </div>
          <div className="about-us-wrapper--details">
            <div className="custom-container">
              {/* <h4 className="mb-2 normal-font">
                We ignite opportunity by organising the healthcare information
                and make it accessible and useful to everyone.
              </h4> */}
              <p className="light-font">
                We ignite opportunity by organising the healthcare information
                and make it accessible and useful to everyone. Good things
                happen when people come together and committed to significantly
                improving the lives of as many people as possible. What started
                as a way to help friends and family to get all healthcare
                providers in a single place led to hundreds and thousands of
                people using the platform all around India in all kinds of ways
                with the help of our technology.
              </p>
              <br></br>
              <p className="light-font">
                <b>Health Tunnel</b> is India’s Largest Healthcare aggregator platform
                with All-in-one Health App and website that helps you and
                provides all services in one platform. The mobile app is
                available in Android and IOS version is under development.
              </p>
            </div>
          </div>
        </div>
        {/* <div
          className="about-letter-cover-wrapper about-letter-cover-wrapper--image position-relative"
          style={{
            backgroundImage: `url('/images/about-us/letter-cover.png')`
          }}
        >
          <div className="custom-container white-color letter-cover-details position-absolute">
            <h3 className="medium-bold-font">A letter from our CEO</h3>
            <p>
              Read about our team’s commitment to provide everyone on our
              platform with the technology that can help them move ahead.
            </p>
            <button className="theme-button">Read Siddharth’s Letter</button>
          </div>
        </div> */}
        <Ourcorevalues />
        <Testimonials />
      </section>
    );
  }
}
