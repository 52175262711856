import React from 'react';
import '@assets/scss/pages/partner-with-us/testimonial.scss';
import { getValue } from '@utils/lodash';

export default class Testimonial extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="testimonial-wrapper">
        <div
          className="testimonial-wrapper--image"
          style={{
            backgroundImage: `url(${
              getValue(this.props, 'categoryInfo.testimonials[0].image', '')
                ?
                  getValue(
                    this.props,
                    'categoryInfo.testimonials[0].image.url',
                    ''
                  )
                : this.props.testimonialObj.personImage
            })`
          }}
        ></div>
        <div
          className="testimonial-wrapper--meta"
          style={{
            backgroundColor: `${this.props.testimonialObj.backgroundColor}`
          }}
        >
          <div className="testimonial-text">
            <h3
              className="normal-font white-color mb-5"
              dangerouslySetInnerHTML={{
                __html: getValue(
                  this.props,
                  'categoryInfo.testimonials[0].description',
                  ''
                )
                  ? getValue(
                      this.props,
                      'categoryInfo.testimonials[0].description',
                      ''
                    )
                  : this.props.testimonialObj.testimonialDetails
              }}
            ></h3>
            <h3 className="normal-font white-color">
              {getValue(this.props, 'categoryInfo.testimonials[0].name', '')
                ? getValue(this.props, 'categoryInfo.testimonials[0].name', '')
                : this.props.testimonialObj.name}
            </h3>
            <p className="light-font">
              {getValue(
                this.props,
                'categoryInfo.testimonials[0].job_title',
                ''
              )
                ? getValue(
                    this.props,
                    'categoryInfo.testimonials[0].job_title',
                    ''
                  )
                : this.props.testimonialObj.job_title}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
