import { Link, useParams } from "react-router-dom";
import { blogs } from "./blogData.js";
import Blog1 from "./blog1";
import Blog2 from "./blog2";
import Blog3 from "./blog3";
import Blog4 from "./blog4";
import Blog5 from "./blog5";
import Blog6 from "./blog6";
import Blog7 from "./blog7";
import Blog8 from "./blog8";
import Blog9 from "./blog9";
import Blog10 from "./blog10";

function BlogPost() {
  const { id } = useParams();

  const componentMap = {
    1: <Blog1 />,
    2: <Blog2 />,
    3: <Blog3 />,
    4: <Blog4 />,
    5: <Blog5 />,
    6: <Blog6 />,
    7: <Blog7 />,
    8: <Blog8 />,
    9: <Blog9 />,
    10: <Blog10 />,
  };

  const content = componentMap[id];

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  }

  return (
    <div className=" add-background ">
      <div className="custom-container py-5 d-flex gap-5 flex-wrap flex-lg-nowrap ">
        {content}
        <div className="">
          <h5>Recent Blogs</h5>
          <div className="recent-blog-section">
            {blogs.map((blog) => (
              <Link
                className="d-flex justify-content-between flex-column recent-container"
                key={blog.id}
                to={`/blog/${blog.id}/${blog.slug}`}
                onClick={handleLinkClick}
              >
                <div>
                  <img
                    src={blog.image}
                    className="blog-cover-image"
                    alt={blog.alt}
                  />
                  <h6 className="my-3 recent-blog-heading">{blog.heading}</h6>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogPost;
