import React from "react";

const FamilyPackage = ({
  packageName,
  price,
  discountedPrice,
  discountPercentage,
  parameter,
  recommenedFor,
  icon
}) => (
  <div className="fluid-container family-wrapper mt-3 mb-3 cursor-pointer">
    <div className="package-name">
      <h5>
        <a href="https://iframepartner.redcliffelabs.com/?pid=3p_Cn5MKekrHp6zJ3QsDH">
          {packageName}
        </a>
      </h5>
    </div>
    <div className="family-middle-section">
      <div className="sub-section d-flex">
        <div className="family-price-section">
          <span>Rs.{price}/-</span>
          <p>Rs.{discountedPrice}/-</p>
        </div>
        <div className="family-discount-section d-flex justify-content-center align-items-center p-2">
          <p>
            {discountPercentage}%<br></br> OFF
          </p>
        </div>
      </div>
      <div className="family-parameter">
        <p>
          <span>{parameter}</span> parameters
        </p>
      </div>
    </div>
    <div className="recommended-section">
      <p>
        Recommended for <span>{recommenedFor}</span>
      </p>
    </div>
    <img
      src={icon}
      alt="Senior Citizen"
      className="image-holder"
    />
  </div>
);

export default FamilyPackage;
