import React, { useEffect, useState } from "react";
import { QueryRequestHelper } from "../../common/query-request-helper";
import { categoriesList } from "../../services/APIs/categories.service";
import { sortJSONObjectArray } from "../../common/text-helpers";
import { getValue } from "../../utils/lodash";
import "../../assets/scss/pages/home-page-v2/index.scss";
import "../../assets/scss/components/home/social-responsibility/responsibility-card.scss";
import "../../assets/scss/components/home/social-responsibility/responsibility.scss";

import CategoryCard from "./components/category-card";
import HomeStaticCard from "./components/static-card";
import GoogleSiteVerification from "../../google-site-verification";

function HomePage(props) {
  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
    getData();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [allCategories_1, setAllCategories_1] = useState([]);
  const [allCategories_2, setAllCategories_2] = useState([]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        populate: "web_image",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await categoriesList(queryRequest);
      console.log("resp", resp);
      if (resp) {
        console.log(resp);
        let allList =
          getValue(resp, `data.length`, 0) > 0
            ? getValue(resp, `data`, []).map((item) => ({
                ...item,
                ...item.attributes,
              }))
            : [];
        let list = sortJSONObjectArray(allList, "priority");
        let list1 = list.filter((_item, index) => index < 4);
        let list2 = list.filter((_item, index) => index > 3);
        setAllCategories_1(list1);
        setAllCategories_2(list2);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                                Helper section                              */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="home-pagev2-wrapper">
      <GoogleSiteVerification />
      <div className="custom-container">
        <h6 className="home-pagev2-wrapper__title text-center">
          One Stop For All Your Healthcare Needs
        </h6>
        <div className="social-resposibility-wrapper--cards social-resposibility-wrapper__margin-bottom social-resposibility-wrapper__support-donate">
          {allCategories_1.map((item, index) => {
            return (
              <div key={index}>
                <CategoryCard item={item} />
              </div>
            );
          })}
        </div>
        <div className="mb-2">
          <HomeStaticCard
            info={{
              url: `https://admin.healthtunnel.com/uploads/website_banner_1_7361311142.png`,
              link: "https://7992.play.quizzop.com/",
            }}
          />
        </div>

        <div className="social-resposibility-wrapper--cards social-resposibility-wrapper__margin-bottom social-resposibility-wrapper__support-donate mt-4">
          {allCategories_2.map((item, index) => {
            return (
              <div key={index}>
                <CategoryCard item={item} />
              </div>
            );
          })}
        </div>
        <HomeStaticCard
          info={{
            url: `https://admin.healthtunnel.com/uploads/web_banner_2_7bfdcd66b0.png`,
            link: "https://play.google.com/store/apps/details?id=com.pillnotify",
          }}
        />
      </div>
    </div>
  );
}

export default HomePage;
