import GoogleSiteVerification from "google-site-verification";
import { Link } from "react-router-dom";
import "@assets/scss/pages/blog/blog.scss";
import { blogs } from '@pages/BlogPost/blogData';

function Blog() {
  return (
    <section className="about-page-wrapper">
      <GoogleSiteVerification />
      <div className="about-us-wrapper">
        <div
          className="about-us-wrapper--image position-relative"
          style={{ backgroundImage: `url('/images/blog/shield.jpg')` }}
        >
          <div className="custom-container about-us-header-container position-absolute">
            <h3>Blog</h3>
          </div>
        </div>
        <div className="custom-container blog-wrapper my-5">
          {blogs.map((blog) => (
            <Link
              className="d-flex justify-content-between flex-column main-blog-cards"
              key={blog.id}
              to={`/blog/${blog.id}/${blog.slug}`}
            >
              <div>
                <img src={blog.image} className="blog-cover-image" alt={blog.alt}/>
                <h5 className="my-4">{blog.heading}</h5>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Blog;
