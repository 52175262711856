import React, { useEffect } from "react";
import GoogleSiteVerification from "../../google-site-verification";
import BackSvgComponent from "../../assets/svg/back";
import { useNavigate } from "react-router-dom";

function BloodDonationPage(props) {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  const socialArray = [
    {
      title: "Ambulance",
      description: "Book Ambulance Online",
      ctatext: "Book Now",
      imageName: "ambulance.png",
      path: `https://vmedo.com/services/ambulance-service-india`,
    },
    {
      title: "Donate Blood",
      description: "Become a blood donor today",
      ctatext: "Register as Donor",
      imageName: "social-card.png",
    },
    {
      title: "Donate Now / Charity",
      description: "Donate for an important cause",
      ctatext: "Donate Now",
      imageName: "social-image3.svg",
      path: `https://unitedonor.com/`,
    },
    {
      title: "Request Blood",
      description: "We match blood donors to those In need",
      ctatext: "Register Now",
      imageName: "social-image-2.svg",
    },
  ];
  const navigate = useNavigate();
  return (
    <div className="home-pagev2-wrapper">
      <GoogleSiteVerification />
      <div className="cursor-pointer mt-3 ms-3" onClick={() => navigate("/")}>
        <BackSvgComponent />
      </div>
      <div className="custom-container">
        <section className="social-resposibility-wrapper">
          <div className="social-resposibility-wrapper--title mb-4 text-center">
            <h2
            // className="home-pagev2-wrapper__title home-pagev2-wrapper__title--margin"
            >
              Support & Donate
            </h2>
          </div>
          <div className="social-resposibility-wrapper--cards social-resposibility-wrapper__margin-bottom social-resposibility-wrapper__support-donate">
            {socialArray.map((responsibilityPropsObj, index) => {
              return (
                <div key={index}>
                  <div
                    className="responsibility-card p-3"
                    style={{
                      backgroundImage: `url(/images/social-resposibility/${responsibilityPropsObj.imageName})`,
                    }}
                  >
                    <h3 className="normal-font">
                      {responsibilityPropsObj.title}
                    </h3>
                    <p className="normal-font" style={{ maxWidth: "220px" }}>
                      {responsibilityPropsObj.description}
                    </p>
                    <a
                      className="theme-button theme-primary-btn cursor-pointer"
                      href={
                        // responsibilityPropsObj.title === 'Ambulance'
                        //   ? `/category/${getValue(this.props, 'ambulance[0].slug', '')}`
                        //   : responsibilityPropsObj.title === 'Donate Now / Charity'?`https://aabsfoundation.com/`:
                        //   'https://play.google.com/store/apps/details?id=com.healthtunnel'
                        responsibilityPropsObj.path
                          ? responsibilityPropsObj.path
                          : "https://play.google.com/store/apps/details?id=com.healthtunnel"
                      }
                      target="_blank"
                    >
                      {responsibilityPropsObj.ctatext}
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </div>
  );
}

export default BloodDonationPage;
