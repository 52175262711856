import React from 'react';
import '@assets/scss/pages/privacy-policy/banner.scss';

export default class InnerpageBanner extends React.Component {
  render() {
    return (
      <section className="inner-page-banner-section-wrapper section-padding">
        <div className="custom-container">
          <h1 className="white-color">Terms of Service</h1>
          <h3 className="white-color light-font">
            It’s important to establish what you can expect from us as you use
            Healthtunnel services and partners listing, and what we expect from
            you.
          </h3>{' '}
        </div>
      </section>
    );
  }
}
