import React from 'react';
import '../../../assets/scss/pages/loading/homev2-loading.scss';
import '../../../assets/scss/pages/loading/loading.scss';
import OrderMedicineLoader from './order-medicine-loader.js';
import OneStopLoader from './slider-loader';
export default function SubCategoriesLoader() {
  return (
    <section className="home-pagev2-wrapper">
      <div className="container-fluid d-flex align-items-center order-medicine-wrapper__header">
        <a href="/">
        {/* <a href="/homev2"> */}
          <img
            src="/images/generic-icons/testimonial-slider-controls/arrow-back.svg"
            className="img-fluid mr-3 cursor-pointer"
          />
        </a>
        <p className="order-medicine-wrapper__header-title-loader animate-loader light-font"></p>
      </div>
      <div className="custom-container">
        <OneStopLoader />
        <OrderMedicineLoader />
      </div>
    </section>
  );
}
