import React from 'react';
import '@assets/scss/pages/about-us-page/our-core-values.scss';

export default class Ourcorevalues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valuesArray: [
      //   {
      //   value: 'Our Core Values',
      //   image: '/images/about-us/our-values/Quality-Care.svg',
      //   description: 'Health Tunnel is India’s Largest Healthcare Community with All-in-one Health platform that helps you and provide accurate listing, special deals and discounts for you'
      // },
      {
        value: 'Quality Care',
        image: '/images/about-us/our-values/Quality-Care.svg',
        description: 'Democratize access to quality primary care – for any citizen anywhere in India.'
      },
      {
        value: 'Affordable',
        image: '/images/about-us/our-values/Affordable.svg',
        description: 'Ensure this access is affordable.'
      },
      {
        value: 'Latest Technology',
        image: '/images/about-us/our-values/Latest-Technology.svg',
        description: 'Leverage digital technology, to optimally use available provider Capacity.'
      },
      {
        value: 'Interoperability',
        image: '/images/about-us/our-values/Interoperability.svg',
        description: 'Promote a system that fully adopts national health standards, and ensure interoperability.'
      }]
    }
  }
  render() {
    const { valuesArray } = this.state;
    return (
      <section className="our-core-values-wrapper section-padding">
          <div className="custom-container">
            <h2>Our Core Values</h2>
            <h3 className="light-font mt-1 mb-4">Health Tunnel is India’s Largest Healthcare Community with All-in-one Health App that helps you and provide Special Deals and Discounts For You</h3>
            <div className="our-core-values-wrapper--values">
                { valuesArray.map((valueObj, index) => {
                    return (
                        <div className="values-card" key={index}>
                            <img src={valueObj.image} />
                            <h3>{valueObj.value}</h3>
                            <p className="light-font">{valueObj.description}</p>
                        </div>
                    )
                }) }
            </div>
          </div>
      </section>
    )
  }
}