import React, { useEffect, useState, useRef } from "react";
import GoogleSiteVerification from "../../google-site-verification";
import SubCategoriesLoader from "../../components/common/order-medicine-loader";
import { NavLink, useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import {
  specificCategory,
  specificSubCategory,
} from "../../services/APIs/categories.service";
import { getValue } from "../../utils/lodash";
import { QueryRequestHelper } from "../../common/query-request-helper";
import SubCategoryCard from "../../components/cards/subcategory-card";
import ProviderCard from "../../components/cards/provider-card";
import Highlight3Card from "../../components/cards/highlight-3-card";
import Highlight1Card from "../../components/cards/highlight-1-card";
import Highlight2Card from "../../components/cards/highlight-2-card";
import ProductCard from "../../components/cards/product-card";
import HowItWorksCard from "../../components/cards/how-it-works";
import { config } from "../../env";
import BackSvgComponent from "../../assets/svg/back";

function SubCategoryDetailPage(props) {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const params = useParams();
  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                                Helper section                              */
  /* -------------------------------------------------------------------------- */

  const [products, setProducts] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const [request, setRequest] = useState({
    name: "",
    phone: "",
    city: "",
    pincode: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [howItWorksBanners, setHowItWorksBanners] = useState([]);
  const getData = async () => {
    try {
      setLoading(true);
      let payload = {
        populate:
          "web_image,highlight.banners.image,highlight_2.banners.image,highlight_3.banners.image,products.cover_image,providers.logo,how_it_works.web_3images.image1,how_it_works.web_3images.image2,how_it_works.web_3images.image3",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await specificSubCategory(
        getValue(params, `slug`, ""),
        queryRequest
      );
      if (resp) {
        setLoading(false);
        setInfo(getValue(resp, `data.attributes`, {}));
        if (
          getValue(
            resp,
            `data.attributes.how_it_works.web_3images.image1.data.attributes.url`,
            ""
          ) ||
          getValue(
            resp,
            `data.attributes.how_it_works.web_3images.image2.data.attributes.url`,
            ""
          ) ||
          getValue(
            resp,
            `data.attributes.how_it_works.web_3images.image3.data.attributes.url`,
            ""
          )
        ) {
          setHowItWorksBanners([
            `${config.MAIN_URL}${getValue(
              resp,
              `data.attributes.how_it_works.web_3images.image1.data.attributes.url`,
              ""
            )}`,
            `${config.MAIN_URL}${getValue(
              resp,
              `data.attributes.how_it_works.web_3images.image2.data.attributes.url`,
              ""
            )}`,
            `${config.MAIN_URL}${getValue(
              resp,
              `data.attributes.how_it_works.web_3images.image3.data.attributes.url`,
              ""
            )}`,
          ]);
        } else {
          setHowItWorksBanners([]);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div>
      <section className="order-medicine-wrapper">
        <GoogleSiteVerification />
        {loading && <SubCategoriesLoader />}
        {!loading && (
          <>
            <div className="container-fluid d-flex align-items-center order-medicine-wrapper__header">
              <NavLink to={`/category-detail/${getValue(params, `main_slug`)}`}>
                <BackSvgComponent />
              </NavLink>
              <p className="order-medicine-wrapper__header-title ms-3">
                {getValue(info, "name", "")}
              </p>
            </div>

            {getValue(info, `sub_categories.data.length`, 0) > 0 && (
              <SubCategoryCard
                subCategories={getValue(info, `sub_categories.data`, [])}
              />
            )}
            {getValue(info, `providers.data.length`, 0) > 0 && (
              <ProviderCard
                providers={getValue(info, `providers.data`, [])}
                slug={getValue(params, "slug", "")}
                info={info}
                title={getValue(info,`description`,'')}
              />
            )}
            <HowItWorksCard howItWorksBanners={howItWorksBanners} />
            {getValue(info, "highlight.banners.length", 0) > 0 && (
              <Highlight1Card highlight={getValue(info, "highlight", {})} />
            )}
            {getValue(info, "highlight_2.banners.length", 0) > 0 && (
              <Highlight2Card
                highlight={getValue(info, "highlight_2", {})}
                customClass="four-in-one-row"
              />
            )}
            {getValue(info, "highlight_3.banners.length", 0) > 0 && (
              <Highlight3Card highlight={getValue(info, "highlight_3", {})} />
            )}
            {getValue(info, "products.data.length", 0) > 0 && (
              <ProductCard
                products={getValue(info, "products.data", [])}
                info={info}
              />
            )}
          </>
        )}
      </section>
    </div>
  );
}

export default SubCategoryDetailPage;
