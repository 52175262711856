import React from "react";
import { getValue } from "../../utils/lodash";
import { config } from "../../env";
export default function Highlight1Card(props) {
  return (
    <>
      <div className="order-medicine-wrapper__category inner-page-section-block-spacing">
        {getValue(props, "highlight.title", "") && (
          <h3
            className="order-medicine-wrapper__category-title text-center mb-4 text-uppercase"
            style={{
              backgroundImage: "url('/images/offers/rectangle-gradient-2.png')",
            }}
          >
            {getValue(props, "highlight.title", "Category Highlight")}
          </h3>
        )}
        <div
          className={`custom-container order-medicine-wrapper__view  ${
            props.customClass || ""
          }`}
        >
          {getValue(props, "highlight.banners", []).map((item) => {
            return (
              <div className="order-medicine">
                <a href={`${getValue(item, `link`, "")}`} target="_blank">
                  <img
                    src={`${config.MAIN_URL}${getValue(
                      item,
                      `image.data.attributes.url`,
                      ""
                    )}`}
                    className="img-fluid order-medicine-wrapper__category-image"
                    height="166"
                    width="535"
                  />
                  <h3 className="text-center order-medicine-wrapper__packages-name">
                    {getValue(item, "title", "")}
                  </h3>
                </a>
              </div>
            );
          })}
          {/* <div className="flex-1 position-relative">
          <img
            src="/images/generic-icons/testimonial-slider-controls/rectangle.png"
            className="img-fluid order-medicine-wrapper__category-image-highlight"
          />
          <p className="order-medicine-wrapper__category-position">
            Highlight 2
          </p>
        </div> */}
        </div>
      </div>
    </>
  );
}
