import { NavLink } from 'react-router-dom';
import '@assets/scss/pages/loan-forms/index.scss';

export default function Success() {
  return (
    <>
      <img
        src="/images/generic-icons/testimonial-slider-controls/arrow-back.svg"
        className="img-fluid mt-2 ml-sm-3 ml-2"
      />
      <section className="success-wrapper text-center">
        <h2>Your Request has been accepted</h2>
        <img
          src="/images/success.svg"
          className="img-fluid success-banner-image"
        />
        <p>
          {/* In case you have any difficulties you can reach out to our customer
          care by mail <span>care@healthtunnel.in</span> */}
          Thank you for Submitting the Request, Our sales Executive will be in
          touch soon. In case of any difficulties please mail us to{' '}
          <span>care@healthtunnel.in</span>
        </p>
        <div className="success-wrapper__cta-wrapper w-100">
          {/* <NavLink to="/homev2">
            <buuton className="success-wrapper__theme-button">
              Go to Labtests
            </buuton>
          </NavLink> */}
          <NavLink to="/">
            {/* <NavLink to="/homev2"> */}
            <button className="success-wrapper__theme-button success-wrapper__primary-btn">
              Go to Home Page
            </button>
          </NavLink>
        </div>
      </section>
    </>
  );
}
