import React from "react";
import Banner from "@components/privacy-policy/banner.js";
import Innerpage from "@components/privacy-policy/inner-page.js";
// import { fetchPages } from '@/services/Sitecreation/fetchPages';
import { getValue } from "@utils/lodash";
import GoogleSiteVerification from "../google-site-verification";
import { getPages } from "@services/APIs/common.service";

export default class Privacypolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      privacy: {},
    };
  }
  componentDidMount = async () => {
    try {
      let response = await getPages();
      if (response) {
        this.setState({
          privacy: getValue(response, `data`, []).find(
            (item) => getValue(item, `attributes.slug`, "") === `privacy`
          ),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    return (
      <div className="privacy-policy-wrapper">
        <GoogleSiteVerification />
        <Banner name={getValue(this.state, "privacy.attributes.name", [])} />
        <Innerpage
          content={getValue(this.state, "privacy.attributes.content", [])}
        />
      </div>
    );
  }
}
