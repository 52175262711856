import React from "react";
import { getValue } from "../../../utils/lodash";
import { config } from "../../../env";
import { useNavigate } from "react-router-dom";

function CategoryCard(props) {
  const navigate = useNavigate();

  const navigatePage = (item) => {
    if (getValue(item, `name`, "") === "HT Smart Clinic") {
      // window.open(getValue(item, `redirect_url`, ""), 1);
      window.location.href = getValue(item, `redirect_url`, "");
    } else if (getValue(item, `name`, "") === "Blood Donation") {
      navigate("/blood-donation");
    } else if (getValue(item, `redirect`, false)) {
      window.open(getValue(item, `redirect_url`, ""), 1);
    } else {
      if (getValue(item, `show_providers`, false)) {
        navigate(`/category-detail/${getValue(item, `id`, "")}`);
      } else {
        navigate("/weight-loss");
      }
    }
  };

  return (
    <div
      className="responsibility-card cursor-pointer"
      style={{
        backgroundImage: `${config.MAIN_URL}${getValue(
          props,
          `item.web_image.data.attributes.url`,
          ""
        )}`,
      }}
      onClick={
        () => navigatePage(props.item)
        // navigate(`/category-detail/${getValue(props, `item.id`, "")}`)
      }
    >
      <img
        src={`${config.MAIN_URL}${getValue(
          props,
          `item.web_image.data.attributes.url`,
          ""
        )}`}
        width={"100%"}
        height={"100%"}
      />
    </div>
  );
}

export default CategoryCard;
