import React from "react";
import "@assets/scss/pages/blog/blog.scss";
import { Link } from "react-router-dom";

const bulletPoints = [
  {
    heading: "Weight Loss Program",
    statement:
      "Discover effective weight-loss strategies, personalized diet plans, and targeted exercise routines.    ",
  },
  {
    heading: "PCOS Reversal Program",
    statement:
      "Unlock natural solutions for managing PCOS through nutrition, hormonal balance, and mindful practices.",
  },
  {
    heading: "Diabetic Reversal Program ",
    statement:
      "Explore ways to reverse type 2 diabetes, including low-carb diets and lifestyle modifications.",
  },
  {
    heading: "Yoga Sessions ",
    statement:
      "Experience stress relief, flexibility, and strength through rejuvenating yoga practices.",
  },
];

const updatedFeatures = [
  {
    link: "/category-detail/3",
    heading: "Order medicines online for swift delivery.",
    statement:
      "Our revamped website streamlines the process of ordering medicines. With just a few clicks, you can have your medications delivered promptly to your doorstep.",
  },
  {
    link: "/category-detail/2",
    heading: "Quick and Easy Lab Test Booking Online",
    statement:
      "Book lab tests hassle-free through our user-friendly platform. Choose from multiple vendors and schedule your tests conveniently.",
  },
  {
    heading: "Consult doctors online and get medical second opinions.",
    statement:
      "Connect with expert doctors virtually. Seek medical advice, discuss your concerns, and receive second opinions—all from the comfort of your home.",
  },
];

function Blog1() {
  return (
    <div>
      <h2>Navigating the New HealthTunnel: A Comprehensive Guide</h2>
      <div className="my-3">
        <p>
          Health Tunnel serves as a one-stop solution, offering a diverse range
          of healthcare services seamlessly. Opting for HealthTunnel translates
          into prioritizing not just healthcare but also ease, comfort, and a
          steadfast commitment to your best health.
          <br />
          <br />
        </p>
        <p>
          Welcome to the revamped HealthTunnel! Our website has undergone
          exciting updates to better serve your health needs. In this article,
          we’ll explore the latest features and options and how they align with
          public interest.
        </p>
      </div>
      <div className="my-5">
        <h4 className="">What’s New?</h4>
        <img src="/images/blog/whats-new.webp" className="blog-images" />
      </div>
      <div className="mt-5">
        <Link to="/weight-loss" target="blank">
          <h4 className="mb-1 blog-redirect">
            Weight loss, nutrition, fitness, and yoga!
          </h4>
        </Link>
        <ul className="ms-4">
          {bulletPoints.map((points, index) => (
            <li key={index} className="my-3 show-disc">
              <h5 className="section-heading">{points.heading}</h5>
              <p>{points.statement}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-5">
        <Link to="https://htsmartclinic.healthtunnel.com/" target="blank">
          <h4 className="mb-2 blog-redirect">
            The Future of Healthcare with HT Smart Clinics!
          </h4>
        </Link>
        <p className="pb-3">
          HT SMARTCLINICS is a ground-breaking initiative that aims to transform
          the landscape of healthcare services. This state-of-the-art smart
          clinic is equipped with innovative health ATMs that cover an
          impressive 64 parameters and are manned by trained medical staff.{" "}
        </p>
        <img src="/images/blog/ht-smart-clinic.webp" className="blog-images" />
      </div>
      <div className="mt-5">
        <h4 className="mb-1">Updated Features</h4>
        <ul className="ms-4">
          {updatedFeatures.map((points, index) => (
            <li key={index} className="my-3 show-disc">
              {points.link ? (
                <Link to={points.link} target="blank">
                  <h5 className="section-heading blog-redirect">
                    {points.heading}
                  </h5>
                </Link>
              ) : (
                <h5 className="section-heading">
                  {points.heading}
                </h5>
              )}
              <p>{points.statement}</p>
            </li>
          ))}
        </ul>
        <img src="/images/blog/consult-doctor.webp" className="blog-images" />
      </div>
      <div className="mt-5">
        <h4 className="mb-1">Conclusion</h4>
        <p>
          Health Tunnel stands as a reliable choice for your health journey.
          Opting for HealthTunnel translates into prioritizing not just
          healthcare but also ease, comfort, and a steadfast commitment to your
          best health.
        </p>
      </div>
    </div>
  );
}

export default Blog1;
