import React, { useState, useRef, useEffect } from "react";
import {
  createConsulationForm,
  createConsulationPayment,
  getConsulationForm,
  getSpecificConsulationForm,
} from "../../../services/APIs/common.service";
import { toast } from "react-toastify";
import { getValue } from "../../../utils/lodash";
import SimpleReactValidator from "simple-react-validator";
import { useNavigate, useParams } from "react-router-dom";
import CloseSVG from "../../../assets/svg/closeSVG";
import BackSvgComponent from "../../../assets/svg/back";
import axios from "axios";
import Payment from "@components/payment";
import Razorpay from "@components/payment/Razorpay";
import PaymentSummary from "./PaymentSummary";
import ConsultationForm from "./ConsultationForm";
import moment from "moment";

function FreeConsultationForm(props) {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  const params = useParams();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const genderEnum = ["Male", "Female"];
  const lifestyleEnum = ["Active", "Sedentary", "Moderately active"];
  const programEnum = [
    "Weight Loss",
    "PCOS reversal",
    "Diabetic reversal",
    "Yoga Classes",
  ];

  const [request, setRequest] = useState({
    name: "",
    phone: "",
    email: "",
    gender: "Male",
    lifestyle: lifestyleEnum[0],
    program: "Weight Loss",
    is_paid: false,
  });

  const [activeTab, setActiveTab] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({});
  const handleSubmitForm = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      try {
        setIsLoading(true);
        try {
          if (!getValue(request, `is_paid`, false)) {
            let payload = {
              data: {
                name: getValue(request, `name`, ""),
                phone: getValue(request, `phone`, ""),
                email: getValue(request, `email`, ""),
                gender: getValue(request, `gender`, ""),
                lifestyle: getValue(request, `lifestyle`, ""),
                is_paid: false,
              },
            };
            let resp = await createConsulationForm(payload);
            if (resp) {
              toast.success("Submitted successfully");
              navigate(`/weight-loss/free-consultation/thank`);
            }
          } else {
            let payload = {
              data: {
                name: getValue(request, `name`, ""),
                phone: getValue(request, `phone`, ""),
                email: getValue(request, `email`, ""),
                gender: getValue(request, `gender`, ""),
                lifestyle: getValue(request, `lifestyle`, ""),
                is_paid: true,
              },
            };
            let resp = await createConsulationForm(payload);
            if (resp) {
              let options = {
                name: getValue(resp, `name`, ""),
                phone: getValue(resp, `phone`, ""),
                email: getValue(resp, `email`, ""),
                gender: getValue(resp, `gender`, ""),
                lifestyle: getValue(resp, `lifestyle`, ""),
                createdAt: moment(getValue(resp, `createdAt`, "")).format(
                  "YYYY-MM-DD"
                ),
                updatedAt: moment(getValue(resp, `updatedAt`, "")).format(
                  "YYYY-MM-DD"
                ),
                publishedAt: moment(getValue(resp, `publishedAt`, "")).format(
                  "YYYY-MM-DD"
                ),
                is_paid: getValue(resp, `is_paid`, ""),
                key: getValue(resp, `key`, ""),
                description: getValue(resp, `description`, ""),
                order_id: getValue(resp, `id`, ""),
                currency: "INR",
                amount: getValue(resp, `amount`, ""),
                displayAmount: getValue(resp, `displayAmount`, ""),
                prefill: {
                  name: getValue(resp, `prefill.name`, ""),
                  email: getValue(resp, `prefill.email`, ""),
                  contact: getValue(resp, `prefill.phone`, ""),
                },
                receipt: getValue(resp, `receipt`, ""),
                handler: async (response) => {
                  setPaymentInfo(response);
                  let input = {
                    input: {
                      data: {
                        signature: getValue(response, "razorpay_signature", ""),
                        payment_id: getValue(
                          response,
                          "razorpay_payment_id",
                          ""
                        ),
                        order_id: getValue(response, "id", ""),
                      },
                    },
                  };
                  try {
                    const razorpay_order_id = getValue(
                      response,
                      `razorpay_order_id`,
                      ""
                    );
                    const url = `https://admin.healthtunnel.com/api/consultation-form/order_status/${razorpay_order_id}`;
                    const captureResponse = await axios.get(url, {});
                    if (getValue(captureResponse, `data.is_completed`, false)) {
                      navigate(`/weight-loss/free-consultation/thank-you`);
                      setIsLoading(false);
                    } else {
                      toast.error(
                        "Something went wrong, Please try after sometime"
                      );
                    }
                    setIsLoading(false);
                  } catch (err) {
                    setIsLoading(false);
                  }
                },
                theme: {
                  color: "#686CFD",
                },
              };
              const rzp1 = new window.Razorpay(options);
              rzp1.open();
            }
          }
        } catch (error) {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  return (
    <div className="bg-white pb-5">
      <div
        className="cursor-pointer mx-3 pt-3"
        onClick={() => navigate(`/weight-loss`)}
      >
        <BackSvgComponent />
      </div>
      <div className="custom-form-container">
        <div className=" w-100">
          <h4 className="lab-text-contact-form-wrapper__contact-form-title mb-3">
            {activeTab === false
              ? "Book a consultation now"
              : "Confirm details"}
          </h4>
          {/* {activeTab === false ? ( */}
          <ConsultationForm
            request={request}
            setRequest={setRequest}
            handleChange={handleChange}
            simpleValidator={simpleValidator}
            genderEnum={genderEnum}
            programEnum={programEnum}
            handleSubmitForm={handleSubmitForm}
            isLoading={isLoading}
          />
          {/* ) : ( */}
          {/* <PaymentSummary
              isOpen={isModalOpen}
              close={closeModal}
              request={request}
              toggleTab={toggleTab}
            /> */}
          {/* )} */}
          {/* <Payment /> */}
          {/* <Razorpay /> */}
          {/* <div className="text-center">
            <button className="theme-button w-40 theme-primary-button mb-3 mr-2">
              Cancel
            </button>
            {isLoading ? (
              <button className="theme-button w-40 theme-primary-button mb-3">
                Please wait...
              </button>
            ) : (
              <button
                className="theme-button w-40 theme-primary-button mb-3"
                onClick={handleSubmitForm}
              >
                Submit
              </button>
            )}
            <p className="lab-text-contact-form-wrapper__text light-font">
              In case you have any difficulties you can reach out to our
              customer care by mail care@healthtunnel.in
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default FreeConsultationForm;
