import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../assets/scss/pages/page-not-found.scss";
import GoogleSiteVerification from "../google-site-verification";
import { Helmet } from "react-helmet";

const Thanks = () => {
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.textContent = `
  //   !function(f,b,e,v,n,t,s)
  //   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  //   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  //   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  //   n.queue=[];t=b.createElement(e);t.async=!0;
  //   t.src=v;s=b.getElementsByTagName(e)[0];
  //   s.parentNode.insertBefore(t,s)}(window,document,'script',
  //   'https://connect.facebook.net/en_US/fbevents.js');
  //   fbq('init', '906084176911120');
  //   fbq('track', 'CompleteRegistration');
  //   `;
  //   document.head.appendChild(script);

  //   const noscript = document.createElement("noscript");
  //   noscript.textContent = `<img height="1" width="1"
  //   src="https://www.facebook.com/tr?id=906084176911120&ev=PageView
  //   &noscript=1"/>;`;
  //   document.head.appendChild(noscript);

  //   return () => {
  //     document.head.removeChild(script);
  //     document.head.removeChild(noscript);
  //   };
  // }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="page-not-found-wrapper section-padding">
      <Helmet>
        <meta charSet="utf-8" />
        <script>
          {`!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window,document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '906084176911120');
  fbq('track', 'CompleteRegistration');console.log("Running...")`}
        </script>
        {/* <noscript>
          <img
            height="1"
            width="1"
            src="https://www.facebook.com/tr?id=906084176911120&ev=PageView
            &noscript=1"
            alt="Facebook Pixel"
          />
        </noscript> */}
      </Helmet>
      <GoogleSiteVerification />
      <div className="custom-container d-flex justify-content-center align-items-center flex-direction-column-mobile gap-5">
        <div>
          <img
            src="/images/thank-you.webp"
            className="img-fluid not-found-image w-75"
            alt="Error 404"
          />
        </div>
        <div className="page-not-found-wrapper--meta ml-md-5">
          <h1 style={{ lineHeight: "1.2" }}>Submitted Successfully!</h1>
          <p className="mt-3 mb-4">
            Thank you for submitting your request for a weight loss
            consultation. <br />A member of our team will contact you shortly.
          </p>
          <NavLink to="/" className="btn btn-primary">
            Home Page
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Thanks;
