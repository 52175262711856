import React from "react";
import { getValue } from "../../utils/lodash";

function BackSvgComponent(props) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getValue(props, `size`, "") ? getValue(props, `size`) : "32"}
        height={getValue(props, `size`, "") ? getValue(props, `size`) : "32"}
        viewBox="0 0 32 32"
      >
        <g
          id="Group_9761"
          data-name="Group 9761"
          transform="translate(-22 -72)"
        >
          <g
            id="Ellipse_3557"
            data-name="Ellipse 3557"
            transform="translate(22 72)"
            fill="none"
            stroke="#e8f0fe"
            stroke-width="1"
          >
            <circle cx="16" cy="16" r="16" stroke="none" />
            <circle cx="16" cy="16" r="15.5" fill="none" />
          </g>
          <g id="arrow_back-24px" transform="translate(26 76)">
            <path
              id="Path_9129"
              data-name="Path 9129"
              d="M0,0H24V24H0Z"
              fill="none"
            />
            <path
              id="Path_9130"
              data-name="Path 9130"
              d="M20,11H7.83l5.59-5.59L12,4,4,12l8,8,1.41-1.41L7.83,13H20Z"
              fill="#707070"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default BackSvgComponent;
