import { Delete, get, patch, post } from "../helpers/http-handler";
import { config } from "../../env";

export const categoriesList = (queryRequest) =>
  get(`${config.API_URL}/categories?${queryRequest}`);

export const specificCategory = (id, queryRequest) =>
  get(`${config.API_URL}/categories/${id}?${queryRequest}`);

export const specificSubCategory = (id, queryRequest) =>
  get(`${config.API_URL}/sub-categories/${id}?${queryRequest}`);
