import React from "react";
import { Link } from "react-router-dom";

function Blog5() {
  return (
    <div>
      <h2>What is HbA1c?</h2>
      <div className="my-3">
        <p className="mb-3">
          If you are diabetic or have somebody in your family with the
          equivalent, then the notice of HbA1c would most likely ring a bell.
          HbA1c is a typical term utilized by specialists and examination labs
          to really look at diabetes. This is a perusal of the blood tests taken
          from a person's body to determine a general image of the typical
          glucose levels over a period of 3–6 months.
        </p>
        <img
          src="/images/blog/understand-hba1c.webp"
          className="blog-images my-3"
          alt="understand-hba1c"
        />
        <p className="my-3">
          HbA1c represents the glycated hemoglobin. It is found when the protein
          in the red platelets, which convey oxygen, for example, hemoglobin,
          joins with the free glucose in the blood and is conveyed to every one
          of the pieces of the body, thus becoming "glycated.". This can
          undoubtedly be perceived by breaking the name of the test into two
          parts:
        </p>
        <p>HbA = hemoglobin, and 1c = glycosylated</p>
      </div>
      <div className="mt-5">
        <h4 className="mb-1">Is blood glucose the same as HbA1c?</h4>
        <p className="mb-3">
          Blood glucose level (BGL) is the convergence of glucose in the blood
          at a solitary moment, for example, the exact second of the test. This
          can be estimated utilizing a fasting plasma glucose test, which is
          completed utilizing blood from a finger prick or can be taken from a
          blood test from the arm. Its perusal is estimated in mmol/l.
        </p>
        <p className="mb-3">
          While HbA1c gives a more extended-term pattern, similar to a normal,
          of how high your glucose levels have been throughout some stretch of
          time, which goes from weeks to months, A HbA1c test can be taken from
          blood from a finger prick; however, much of the time it is taken as a
          blood test from your arm. The HbA1c esteem is estimated in mmol/mol.
        </p>
        <Link to="https://amzn.to/3P3Bttl" className="link-weight blog-redirect" target="blank">
          Shop Glucometer Now
        </Link>
      </div>
      <div className="mt-5">
        <h4 className="mb-1">What do HbA1c tests measure?</h4>
        <p>
          The HbA1c test counts the quantity of red platelets that are
          glycosylated (joined to sugar) and reports it as a rate; for instance,
          assuming 8 out of every 100 red platelets are connected to sugar, the
          HbA1c result will be 8%. This is not the same as BGL tests, which
          measure how much sugar is uninhibitedly circling in the blood.
        </p>
      </div>
      <div className="mt-5">
        <h4 className="mb-1">
          How does HbA1c make sense of the free sugar in the blood?
        </h4>
        <p className="mb-3">
          At the point when our body processes sugar, the glucose in the
          circulation system has a liking for hemoglobin and gets connected to
          it. How much glucose that joins with this protein is straightforwardly
          corresponding to the aggregate sum of sugar that is in your framework
          around then.
        </p>
        <img
          src="/images/blog/glucometer.webp"
          className="blog-images my-3"
          alt="glucometer"
        />
        <p className="mt-2">
          RBCs in the human body make due for 8–12 weeks before restoration;
          estimating glycated hemoglobin (or HbA1c) is utilized to reflect
          normal blood glucose levels over that length, giving a valuable
          longer-term measure of blood glucose control. Assuming that your
          glucose levels have been high lately, your HbA1c will likewise be more
          noteworthy.
        </p>
      </div>
      <div className="mt-5">
        <h4 className="mb-1">Scopes of HbA1c</h4>
        <p>
          For most extreme adults with diabetes, paying little heed to either
          Type 1 or 2, the overall HbA1c target is &lt;7%, which implies
          preferably 7% or less of red platelets ought to be glycosylated. HbA1c
          levels of 8.0% or above might show that more tight control of blood
          glucose levels is required. In adults who don't have diabetes, the
          HbA1c levels are typically between 4.0 and 6.0%. The lower the HbA1c
          level in individuals with diabetes, the higher the risk of
          hypoglycemia (low blood glucose), so this and different variables,
          like pregnancy, are additionally thought about while drawing a
          definitive report.
        </p>
      </div>
      <div className="mt-5">
        <h4 className="mb-1">Check your HbA1C range.</h4>
        <table className="hba1c-table">
          <tr>
            <th>HbA1c</th>
            <th>Reading</th>
            <th>%</th>
          </tr>
          <tr>
            <td>Normal</td>
            <td>Below 42 mmol/mol</td>
            <td>Below 6.0%</td>
          </tr>
          <tr>
            <td>Prediabetes</td>
            <td>42 to 47 mmol/mol</td>
            <td>6.0% to 6.4%</td>
          </tr>
          <tr>
            <td>Diabetes</td>
            <td>48 mmol/mol or over</td>
            <td>6.5% or over</td>
          </tr>
        </table>
        <img
          src="/images/blog/what-is-hba1c.webp"
          className="blog-images mt-4"
          alt="what-is-hba1c"
        />
      </div>
      <div className="mt-5">
        <h4 className="mb-1"> Who ought to get HbA1c tested?</h4>
        <p className="mb-3">
          HbA1c testing is particularly significant on the grounds that, in the
          beginning phases, diabetes has no side effects. Albeit no test is
          great, the HbA1c and blood glucose tests are the most ideal
          instruments that anyone could hope to find to analyze diabetes—aa
          serious and long-lasting illness.
        </p>
        <p>
          Testing empowers medical services suppliers to find and treat diabetes
          before difficulties happen and to find and treat prediabetes,
          subsequently deferring or forestalling the rate of type 2 diabetes.
        </p>
        <img
          src="/images/blog/glucometer-2.webp"
          className="blog-images mt-4"
          alt="glucometer"
        />
      </div>
      <div className="mt-5">
        <h4 className="mb-1">How do you want to get started?</h4>
        <p>
          It is a basic test, and no fasting is required. You can eat and drink
          typically. A specialist essentially takes an example of blood by
          embedding a needle into a vein in your arm using a smartprick or
          pricking the tip of your finger with a little, pointed lancet.{" "}
          <Link
            to="https://play.google.com/store/apps/details?id=com.healthtunnel"
            className="link-weight blog-redirect"
            target="blank"
          >
            Download the HealthTunnel app
          </Link>
          , which is easy to access.{" "}
          <Link to="/" className="link-weight blog-redirect" target="blank">
            Book a HbA1c test via HealthTunnel
          </Link>{" "}
          for analysis. You can get back to your standard exercises right away.
        </p>
      </div>
    </div>
  );
}

export default Blog5;
