import React from 'react';
import '@assets/scss/pages/privacy-policy/banner.scss';

export default class InnerpageBanner extends React.Component {
  render() {
    return (
      <section className="inner-page-banner-section-wrapper section-padding">
        <div className="custom-container">
          <h1 className="white-color">Privacy</h1>
          <h3 className="white-color light-font">
            When you use our services, you’re trusting us with your information.
            We work hard to protect your information and put you in control.
          </h3>
        </div>
      </section>
    );
  }
}
