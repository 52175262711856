import React from "react";
import { getValue } from "../../utils/lodash";
import { config } from "../../env";
export default function HowItWorksCard(props) {
  return (
    <>
      <div className="order-medicine-wrapper__category order-medicine-wrapper__category--bg-color inner-page-section-block-spacing">
        {getValue(props, "howItWorksBanners.length", 0) > 0 && (
          <h3
            className="order-medicine-wrapper__category-title mb-4 text-uppercase order-medicine-wrapper__service-provider-title text-center"
            style={{
              backgroundImage: "url('/images/offers/rectangle-gradiant-3.png')",
            }}
          >
            {"How does it work"}
          </h3>
        )}
        <div className="custom-container d-flex align-items-center justify-content-center">
          {getValue(props, "howItWorksBanners", []).map((item) => {
            return (
              <div className="text-center order-medicine-wrapper__highlight3">
                <img
                  src={`${item}`}
                  className="order-medicine-wrapper__how-image img-fluid"
                  width="255"
                  height="166"
                />
              </div>
            );
          })}
          {/* <div className="text-center">
          <img
            src="/images/generic-icons/testimonial-slider-controls/image3highlight.png"
            className="img-fluid mr-3 ml-3 order-medicine-wrapper__highlight3-image"
            width="162"
          />
          <p className="order-medicine-wrapper__highlight-title">
            Sexual Wellness
          </p>
        </div>
        <div className="text-center">
          <img
            src="/images/generic-icons/testimonial-slider-controls/image2highlight.png"
            className="img-fluid order-medicine-wrapper__highlight3-image"
            width="162"
          />
          <p className="order-medicine-wrapper__highlight-title">
            Ortho Pain Relief
          </p>
        </div> */}
        </div>
      </div>
    </>
  );
}
