import React from "react";
import { Link } from "react-router-dom";

const bulletPoints = [
  {
    heading: "Eliminate mosquito breeding sites",
    statement:
      "Mosquitoes that transmit dengue fever breed in stagnant water. Get rid of standing water from things such as containers, flower pots, gutters, etc., which may serve as potential breeding sites around your house.",
  },
  {
    heading: "Use insect repellents",
    statement:
      "Apply DEET-based insect repellent or wear clothes treated with permethrin on exposed parts of your body when going outdoors. When it is peak mosquito time outside, put on long sleeved shirts as well as long pants.",
    image: "/images/blog/prevention-of-dengue.webp",
    alt: "tips-to-prevent-dengue",
  },
  {
    heading: "Install screens and use bed nets",
    statement:
      "Use window screens and doors not to let mosquitoes in homes you live in. Especially if you live in a high transmission area for dengue fever, sleep under a bed net at night.",
  },
];

function Blog8() {
  return (
    <div>
      <h2>
        Surviving Dengue: A Comprehensive Guide to Prevention and Treatment
      </h2>
      <div className="my-3">
        <p>
          Dengue fever is one of the diseases caused by mosquitoes that affect
          millions of people all over the world each year, and hence knowing how
          to identify its symptoms, treat it and stop its spreading is important
          in order to prevent the associated public health impacts. Will discuss
          dengue fever complexities that provide full information needed so that
          you can have awareness on safeguarding yourself.
        </p>
      </div>
      <div className="mt-5">
        <h4 className="mb-1 ">Symptoms</h4>
        <p>
          Typical dengue fever begins with flu-like illness including high
          fever, severe headache pain at the back of the eyes, joint and muscle
          pains, fatigue, nausea, vomiting and a characteristic skin rash. It
          can progress to dengue hemorrhagic fever or dengue shock syndrome
          which are considered as severe cases characterised by bleeding
          extensively or blood vessels leaking inside body tissues.
        </p>
        <img
          src="/images/blog/symptoms-of-dengue.webp"
          className="blog-images mt-2"
          alt="symptoms-of-dengue"
        />
      </div>
      <div className="mt-2">
        <h4 className="mb-1">Treatment</h4>
        <p>
          There is no specific treatment for dengue fever. Treatment deals
          mainly with relieving its symptoms and avoiding complications.
          Patients are advised to rest, drink enough water and take
          over-the-counter pain medications such as acetaminophen to reduce
          fevers and relieve their pain. Note that nonsteroidal
          anti-inflammatory drugs (NSAIDs) including aspirin and ibuprofen must
          be avoided since they can increase the risk of bleeding.{" "}
          <Link to="/" className="link-weight blog-redirect" target="blank">
            HealthTunnel Smart Clinics
          </Link>{" "}
          offe­r complete dengue­ tests for accurate diagnosis and disease­
          tracking. Their advanced tests and skille­d staff detect and manage
          de­ngue early. This helps patie­nts recover safely and quickly by
          ge­tting the care they ne­ed.
        </p>
        <img
          src="/images/blog/doctor-with-thermometer.webp"
          className="blog-images mt-4"
          alt="doctor-with-thermometer"
        />
      </div>
      <div className="mt-5">
        <h4 className="mb-1">Prevention</h4>
        <p>
          To prevent dengue fever, people have to control mosquito populations
          and avoid mosquito bites. The following points sum up some of the
          preventive measures:
        </p>
        <ul className="ms-4">
          {bulletPoints.map((points, index) => (
            <li key={index} className="my-3 show-disc">
              <h5 className="section-heading">{points.heading}</h5>
              <p>{points.statement}</p>
              {points.image && (
                <img
                  src={points.image}
                  className="blog-images "
                  alt={points.alt}
                />
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-5">
        <h4 className="mb-1">Stay informed</h4>
        <p>
          Always know what is happening on the local side with dengue fever and,
          whenever you travel to regions where it’s endemic, take necessary
          measures in advance. Stay vigilant about symptoms of disease and if
          you or someone you know has any suspicion of having dengue fever, seek
          medical care early.
        </p>
      </div>
      <div className="mt-5">
        <h4 className="mb-1">Conclusion</h4>
        <p>
          Dengue fever is still a serious global public problem in numerous
          areas. By familiarizing themselves with its manifestations, options
          for treatment and prevention strategies, individuals may adopt a
          proactive approach towards safeguarding their lives and community
          against this mosquito-borne disease. Mosquito control, public
          awareness programs and research are collective interventions that can
          help reduce the burden of dengue fever worldwide as well as increase
          general health standards. For more Information do visit {" "}
          <Link to="/" className="link-weight blog-redirect" target="blank">
            HealthTunnel
          </Link>.
        </p>
      </div>
    </div>
  );
}

export default Blog8;
