import React from "react";
import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import thyrocareLogo from "@assets/images/thyrocare/thyrocare-logo.svg";
import thyrocareBanner from "@assets/images/thyrocare/thyrocare-banner.webp";
import "./thyrocare.scss";
import { useNavigate } from "react-router-dom";
import BackSvgComponent from "../../assets/svg/back";
import LeadModal from "@components/lead-form";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import 'react-tabs/style/react-tabs.css';

const thyrocarePackage = [
  {
    heading: "Top Booked Tests",
    subheading:
      "Introducing best health tests at best rates with free home sample collection. Check out our top booked tests now that meet all your health needs.",
    packages: [
      {
        title: "Kidpro",
        testIncluded: "7",
        packageIncludes: ["Renal"],
        actualPrice: "850",
      },
      {
        title: "D-Dimer",
        testDetails:
          "Quantitative D-Dimer, D-Dimer (Qantitative) Test, Quantitative D-Dimer, D-Dimer TEst, d-dimer...",
        actualPrice: "1200",
      },
      {
        title: "Beta Hcg",
        testDetails: "Beta HCG test, Beta HCG blood test",
        actualPrice: "750",
      },
      // {
      //   title: "25-Oh Vitamin D (Total)",
      //   testDetails:
      //     "Vitamin D, Vitamin D3 (25-OH Cholecalciferol) Test, 25-Hydroxy Vitamin D Test, Vitamin D 25...",
      //   actualPrice: "1000",
      // },
    ],
  },
  {
    heading: "Top Booked Profiles",
    subheading:
      "Making preventive healthcare and diagnostic services more accessible and affordable to you. Our quality helps us provide you the best. Check out our top booked profiles now.",
    filters: [
      "Popular Tests",
      "Mens Health",
      "Women’s Health",
      "Fever Profile",
    ],
    popularTests: [
      {
        title: "  Aarogyam Xl With Utsh",
        testIncluded: "145",
        packageIncludes: [
          "Arthritis",
          "Cardiac Risk Markers",
          "Complete Hemogram",
        ],
        actualPrice: "8499",
      },
      {
        title: "  Aarogyam 1.3 With Utsh",
        testIncluded: "96",
        packageIncludes: [
          "Cardiac Risk Markers",
          "Complete Hemogram",
          "Diabetes",
        ],
        actualPrice: "2999",
      },
      {
        title: "  Complete Tyroid Checkup ",
        testIncluded: "5",
        packageIncludes: ["Thyroid"],
        actualPrice: "775",
      },
    ],
    mensTest: [
      {
        title: "Male Hormone Screening Panel",
        testIncluded: "6",
        packageIncludes: ["Hormone", "Infertility", "Metabolic"],
        actualPrice: "3500",
      },
      {
        title: "Mens Hairfall Screening- Advanced",
        testIncluded: "43",
        packageIncludes: [
          "Arthrities",
          "Cardiac Risks Markers",
          "Complete Hemogram",
        ],
        actualPrice: "2749",
      },
      {
        title: "Male Cancer Screening Profile",
        testIncluded: "7",
        packageIncludes: ["Cancer Markers", "Cardiac Risks Markers"],
        actualPrice: "3000",
      },
    ],
    womenHealth: [
      {
        title: "Pcod Profile",
        testIncluded: "33",
        packageIncludes: ["Complete Hemogram", "Diabetes", "Hormone"],
        actualPrice: "3000",
      },
      {
        title: "Womens Hairfall Screening- Advanced",
        testIncluded: "47",
        packageIncludes: [
          "Arthrities",
          "Cardiac Risks Markers",
          "Complete Hemogram",
        ],
        actualPrice: "2749",
      },
      {
        title: "Infertirtility Profile- Advanced",
        testIncluded: "21",
        packageIncludes: ["Hypertension", "Infertility", "Steroid"],
        actualPrice: "4000",
      },
    ],
    feverProfile: [
      {
        title: "H3n2 Plus Influenza Profile",
        testIncluded: "4",
        packageIncludes: ["Infection"],
        actualPrice: "4250",
      },
      {
        title: "Influenza Panel (Influenza AA and and H1n1",
        testIncluded: "3",
        packageIncludes: ["Infection"],
        actualPrice: "3000",
      },
      {
        title: "Monsoon Fever Panel- Advanced",
        testIncluded: "36",
        packageIncludes: ["Cardiac Risk Markers", "Complete Hemogram", "Fever"],
        actualPrice: "2000",
      },
    ],
  },
];

const renderPackageDetails = (packages) => {
  return packages.map((packageItem, i) => (
    <div key={i} className="t-package-container">
      <div className="">
        <h6 className="mb-4 t-package-name">{packageItem.title} </h6>
        {packageItem.testIncluded ? (
          <p className="t-package-included-text">
            {packageItem.testIncluded} Tests Included
          </p>
        ) : (
          <div>
            <p className="t-package-included-text mb-2">Also knows as</p>
            {packageItem.testDetails}
          </div>
        )}
        {packageItem.packageIncludes && (
          <ul className="t-package-details">
            {packageItem.packageIncludes.map((include, j) => (
              <li key={j}>{include}</li>
            ))}
          </ul>
        )}
      </div>
      <div className=" t-package-footer mt-2">
        <p className="t-package-price">₹{packageItem.actualPrice}</p>
        {/* <button className="t-book-now">Book Now</button> */}
        <LeadModal
          bookNowClass="t-book-now"
          url="https://www.healthtunnel.com/thyrocare"
          providerName="Thyrocare"
        />
      </div>
    </div>
  ));
};

function Thyrocare() {
  const navigate = useNavigate();

  return (
    <div className="pt-3 pb-5 thyro-background">
      <div className="cursor-pointer mx-3" onClick={() => navigate("/")}>
        <BackSvgComponent />
      </div>
      <div className="d-flex justify-content-center ">
        <img src={thyrocareLogo} className="thyrocare-logo mb-2" />
      </div>
      <div className="my-3">
        <img src={thyrocareBanner} className="thyrocare w-100" />
      </div>
      <div className=" container my-5">
        <div>
          {thyrocarePackage.map((entry, index) => (
            <div key={index} className="mt-5">
              <h3 className="text-center my-3">{entry.heading}</h3>
              <div className="d-flex justify-content-center flex-column align-items-center">
                <p className="t-package-subheading">{entry.subheading}</p>
                <div className="thick-bottom-border"></div>
              </div>
              {entry.filters && (
                <Tabs>
                  <TabList>
                    {entry.filters.map((filter, i) => (
                      <Tab key={i}>{filter}</Tab>
                    ))}
                  </TabList>

                  <TabPanel className="t-package-wrapper">
                    {entry.popularTests &&
                      renderPackageDetails(entry.popularTests)}
                  </TabPanel>
                  <TabPanel className="t-package-wrapper">
                    {entry.mensTest && renderPackageDetails(entry.mensTest)}
                  </TabPanel>
                  <TabPanel className="t-package-wrapper">
                    {entry.womenHealth &&
                      renderPackageDetails(entry.womenHealth)}
                  </TabPanel>
                  <TabPanel className="t-package-wrapper">
                    {entry.feverProfile &&
                      renderPackageDetails(entry.feverProfile)}
                  </TabPanel>
                </Tabs>
              )}
              <div className="t-package-wrapper">
                {entry.packages && renderPackageDetails(entry.packages)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Thyrocare;
