import React from "react";
import "./redcliff.scss";
import BookedItem from "../redcliff/components/BookedItem";
import HealthPackage from "../redcliff/components/HealthPackages";
import FamilyPackage from "../redcliff/components/FamilyPackage";
import WhyChoose from "../redcliff/components/WhyChoose";
// import Sitelogo from "@/assets/svg/logo.svg";
import { GiHeartOrgan } from "react-icons/gi";
import { MdBloodtype, MdHearing  } from "react-icons/md";
import { IoBody } from "react-icons/io5";   
import { FaRegEye } from "react-icons/fa";

function Redcliff() {
  const frequentlyBookedItems = [
    {
      icon: <MdBloodtype size={30} />,
      text: "Blood",
    },
    {
      icon: <GiHeartOrgan size={30}/>,
      text: "Heart",
    },
    {
      icon: <MdHearing size={30}/>,
      text: "Ear",
    },
    {
      icon: <IoBody size={26}/>,
      text: "Full body",
    },
    {
      icon: <FaRegEye size={26}/>,
      text: "Eye",
    },
    // {
    //   icon: faBone,
    //   text: "Ortho",
    // },
  ];

  const healthPackageDetails = [
    {
      planName: "Advance Plus Full Body Checkup",
      reportTime: "15",
      parameter: "98",
      details:
        "It assesses your risks for potential health diseases and helps in health monitoring.",
      discountedPrice: "2099",
      price: "8760",
      discountPercentage: "76",
    },
    {
      planName: "Fit India Full Body checkup with Vitamin Screening",
      reportTime: "15",
      parameter: "98",
      details:
        "It includes many tests that help in the early diagnosis of health diseases and monitor health.",
      discountedPrice: "1499",
      price: "6400",
      discountPercentage: "76",
    },

    {
      planName: "Smart Full Body Check up with Vitamin D",
      reportTime: "15",
      parameter: "98",
      details:
        "It screens vitamin levels & helps diagnose vitamin deficiency & other health issues.",
      discountedPrice: "1099",
      price: "4260",
      discountPercentage: "74",
    },
    {
      planName: "Smart Full Body Checkup",
      reportTime: "15",
      parameter: "98",
      details:
        "Comprehensive routine checkup for holistic health evaluation & detection of potential health issues.",
      discountedPrice: "799",
      price: "2199",
      discountPercentage: "63",
    },
  ];

  const familyPackageDetails = [
    {
      packageName: "Senior Citizen Full Body Checkup",
      recommenedFor: "Mother",
      price: "8760",
      discountedPrice: "2099",
      discountPercentage: "76",
      parameter: "83",
      icon: `/images/redcliff/grandmother.png`,
    },
    {
      packageName: "Senior Citizen Full Body Checkup",
      recommenedFor: "Father",
      price: "8760",
      discountedPrice: "2099",
      discountPercentage: "76",
      parameter: "83",
      icon: `/images/redcliff/senior-citizen.png`,
    },
    {
      packageName: "Fit India Full Body Checkup With Vitamin",
      recommenedFor: "Men",
      price: "6400",
      discountedPrice: "1499",
      discountPercentage: "76",
      parameter: "83",
      icon: `/images/redcliff/men.png`,
    },
    // {
    //   packageName: "Senior Citizen Full Body Checkup",
    //   recommenedFor: "Son",
    //   price: "8760",
    //   discountedPrice: "209",
    //   discountPercentage: "6",
    //   parameter: "83",
    // },
  ];

  const whyChooseDetails = [
    {
      icon: `/images/redcliff/whychoose-1.webp`,
      text: "Smart Reports In 24 hours*",
    },
    {
      icon: `/images/redcliff/whychoose-2.webp`,
      text: "Free Reports Consultation",
    },
    {
      icon: `/images/redcliff/whychoose-3.webp`,
      text: "Most Affordable Pricing",
    },
    {
      icon: `/images/redcliff/whychoose-4.webp`,
      text: "On-time Sample Collection",
    },
    {
      icon: `/images/redcliff/whychoose-5.webp`,
      text: "NABL Certified Labs",
    },
    {
      icon: `/images/redcliff/whychoose-6.webp`,
      text: "Presence In 220+ Cities",
    },
  ];

  return (
    <div className="redcliff-wrapper container">
      <div className="d-flex justify-content-between pt-4 row">
        <img
          src="/images/redcliff/redcliff.webp"
          alt="Redcliff logo"
          className="redcliff-logo col-md-2 m-2 col-sm-3"
        />
        {/* <div className="d-flex align-items-center">
          <img
            src={Sitelogo}
            className="img-fluid"
            width="38"
            height="38"
            alt="HEALTH TUNNEL"
          />
          <div className="logo-wrapper--meta ml-2">
            <h6 className="bold-font">
              HEALTH TUNNEL <sup>&#174;</sup>
            </h6>
            <p className="small-font d-none-mobile">Well Beyond Healthcare</p>
          </div>
        </div> */}
      </div>
      <div className="mt-4">
        <h4>Frequently booked</h4>
        <div className="frequiently-booked-wrapper">
          {frequentlyBookedItems.map((item, index) => (
            <BookedItem key={index} icon={item.icon} text={item.text} />
          ))}
        </div>
      </div>

      <div className="mt-5 health-wrapper">
        <h4 className="pt-3">Top Health packages</h4>
        <div className="row">
          {healthPackageDetails.map((item, index) => (
            <div className="col-lg-4 col-md-6 mb-3">
              <HealthPackage
                key={index}
                planName={item.planName}
                reportTime={item.reportTime}
                parameter={item.parameter}
                details={item.details}
                discountedPrice={item.discountedPrice}
                price={item.price}
                discountPercentage={item.discountPercentage}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="mt-5">
        <h4>Family care packages</h4>
        <div className="row">
          {familyPackageDetails.map((item, index) => (
            <div className="col-lg-4 col-md-6">
              <FamilyPackage
                key={index}
                packageName={item.packageName}
                recommenedFor={item.recommenedFor}
                price={item.price}
                discountedPrice={item.discountedPrice}
                discountPercentage={item.discountPercentage}
                parameter={item.parameter}
                icon={item.icon}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="whychoose-wrapper pt-5 pb-5 mt-5 mb-5">
        <h4 className="mb-3">Why Choose us</h4>
        <div className="row">
          {whyChooseDetails.map((item, index) => (
            <div className="col-lg-4 mb-3 col-md-6 col-sm-6">
              <WhyChoose key={index} icon={item.icon} text={item.text} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Redcliff;
