import React from "react";
import { Link } from "react-router-dom";

const fitnessTip = [
  {
    heading: "Warm-Up",
    statement:
      "Always warm up before your workout to prevent injuries and optimize the effectiveness of your exercises.",
  },
  {
    heading: "Start Slow",
    statement:
      "Gradually increase your speed and intensity as you build strength. Rome wasn't built in a day, and neither is a healthier you.    ",
  },
  {
    heading: "Listen to Your Body",
    statement:
      "Pay attention to how your body feels during the workout. If something doesn't feel right, don't hesitate to modify the exercise or take a break.",
  },
  {
    heading: "Stay Hydrated",
    statement:
      "Water is your workout buddy. Ensure you stay hydrated before, during, and after your workout to keep your body performing at its best.",
  },
];

function Blog3() {
  return (
    <div>
      <h2>Burn Calories Fast: 5-Minute Weight Loss Challenge</h2>
      <div className="my-3">
        <h4 className="mb-1">
          HealthTunnel: Your Gateway to Health and Wellness
        </h4>
        <p>
          Are you on a journey to enhance your fitness and embrace a healthier
          lifestyle? Look no further than fitness experts, our dedicated
          nutritionist and fitness coaches at HealthTunnel. Our Fitness Coaches
          specialise in crafting personalised coaching and nutrition plans
          designed to propel you towards your fitness goals. Join us in this
          wellness expedition, and let's sculpt a healthier version of you
          together.
        </p>
        <img
          src="/images/blog/fat-burning.webp"
          className="blog-images mt-3"
          alt="fat-burning"
        />
      </div>
      <div className="mt-5">
        <h4 className="mb-1">
          <Link
            to="https://www.youtube.com/watch?v=CkmnG4AkkcI"
            className="blog-redirect"
            target="blank"
          >
            Quick Cardio Blast for Beginners:
          </Link>{" "}
          A 10-Minute Routine
        </h4>
        <p>
          Embarking on a fitness journey can be intimidating, especially if
          you're new to cardio exercises. That's why fitness experts have
          designed a beginner-friendly 10-minute workout to kickstart your
          fitness routine. This invigorating routine features five popular
          exercises: Jumping Jacks, Squats, High Knees, Burpees, and Mountain
          Climbers. Each exercise is performed for 30 seconds, followed by a
          30-second rest period. It's the perfect way to elevate your heart rate
          and enhance your overall fitness level.
        </p>
        <img
          src="/images/blog/quick-cardio.webp"
          className="blog-images mt-3"
          alt="preparing-cardio-workout"
        />
      </div>
      <div className="mt-5">
        <h4 className="mb-1">Exercise Variations for All Fitness Levels</h4>
        <p>
          Understanding that everyone's fitness journey is unique, Our Experts
          provide variations for each exercise to cater to different fitness
          levels. If you're just starting or need a gentler approach, we've got
          you covered with scale-down versions. For instance, modify burpees by
          stepping back and forward instead of jumping. High knees too
          challenging? Begin with a marching-in-place variation, gradually
          increasing intensity.
        </p>
        <img
          src="/images/blog/excercise-varitation.webp"
          className="blog-images my-4"
          alt="importance-of-excercise-varition"
        />
        <p>
          For those seeking an extra challenge, we introduce hand plank
          variations to engage your upper body and core while keeping that heart
          rate up.
        </p>
      </div>
      <div className="mt-5">
        <h4 className="mb-1">Top Fitness Tips</h4>
        <p>
          Before you dive into these invigorating exercises, here are some
          essential tips from Our Experts to maximize your workout experience
        </p>
        <ol className="">
          {fitnessTip.map((points, index) => (
            <li key={index} className="my-3 add-ol-style">
              <h5 className="section-heading">{points.heading}</h5>
              <p>{points.statement}</p>
            </li>
          ))}
        </ol>
      </div>

      <div className="mt-5">
        <p>
          Ready to embark on this journey to a healthier, fitter you? Grab your
          towel, water bottle, and let's dive into the invigorating world of
          fitness with fitness experts at HealthTunnel! For more healthcare
          insights and engaging content, visit our website, and don't forget to
          follow us on social media platforms and subscribe to our{" "}
          <Link
            to="https://www.youtube.com/@HealthTunnel"
            className="link-weight blog-redirect"
            target="blank"
          >
            YouTube channel
          </Link>
          . Your path to holistic health begins here!
        </p>
      </div>
    </div>
  );
}

export default Blog3;
