import React from 'react';
import '@assets/scss/components/home/testimonials/testimonials.scss';
import Trianglesvg from '@assets/images/Rectangle 9341.svg';
import Trianglenewsvg from '@assets/images/rectangle.svg';
import Circlesvg from '@assets/images/Ellipse 1440.svg';
import Icon from '@assets/images/format_quote.svg';

export default class Testimonials extends React.Component {
  render() {
    return (
      <section className="tesimonials-wrapper">
        <div className="custom-container">
          <div className="d-flex align-items-center justify-content-between flex-direction-column-mobile flex-direction-column-tablet">
            <div className="tesimonials-wrapper--title">
              <h3 className="normal-font">Testimonials</h3>
              <h2 className="normal-font">
                Here’s why customer Choose Healthtunnel
              </h2>
              {/* <button className="theme-button theme-primary-btn">
                View More
              </button> */}
            </div>
            <div className="tesimonials-wrapper--information">
              <div className="information-container d-flex align-items-end flex-direction-column-mobile">
                <img src={Trianglesvg} className="d-none-mobile" />
                <div className="text mr-md-3 mb-3 mb-sm-0">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-heading">
                      <h6>Abhilash SK</h6>
                      <p className="">First Advantage, Operations</p>
                    </div>
                    <div className="icon">
                      <img src={Icon} />
                    </div>
                  </div>
                  <p>
                    I had a good experience of using healthtunnel to set up my
                    blood test. Firstly it is so easy that it gives multiple
                    providers and Just got the most suitable ones without
                    wasting time.
                  </p>
                </div>
                <div className="text">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-heading">
                      <h6>Ashutosh Pradhan</h6>
                      <p className="">Capgemini, HR</p>
                    </div>
                    <div className="icon">
                      <img src={Icon} />
                    </div>
                  </div>
                  <p>
                    It was so difficult to find N95 mask during the covid
                    crisis. The healthtunnel pointed at the right providers and
                    bought in no time.
                  </p>
                </div>
              </div>
              <div className="information-container d-flex align-items-end mt-3 mb-sm-3 mt-sm-3 flex-direction-column-mobile">
                <div className="text mr-md-3 mb-3 mb-md-0">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-heading">
                      <h6>Amrita Balakrishna</h6>
                      <p className="">Cabin Manager, Indigo</p>
                    </div>
                    <div className="icon">
                      <img src={Icon} />
                    </div>
                  </div>
                  <p>
                    I recommend Healthtunnel service for diagnostic services as
                    well. They have tie-ups with a large number of labs across
                    the country. Top notch customer service.
                  </p>
                </div>

                <div className="text">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-heading">
                      <h6>Chandrashekar MS</h6>
                      <p className="">Retired Major, Indian Army</p>
                    </div>
                    <div className="icon">
                      <img src={Icon} />
                    </div>
                  </div>
                  <p>
                    I began to use Healthtunnel when I needed a homeopathic
                    medicine that was not available elsewhere. From that time
                    on, this site has become my all time go-to.
                  </p>
                </div>
                <img src={Trianglenewsvg} className="d-none-mobile" />
              </div>
              <div className="information-container d-flex align-items-end flex-direction-column-mobile">
                <img src={Circlesvg} className="d-none-mobile" />
                <div className="text mt-3 mt-xs-0 mt-sm-0 mr-md-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-heading">
                      <h6>Ramesh AK</h6>
                      <p className="">IT Consultant, TCS</p>
                    </div>
                    <div className="icon">
                      <img src={Icon} />
                    </div>
                  </div>
                  <p>
                    This site has become a one stop place for me to go for all
                    the healthcare related help, especially for my father and
                    mother who needs support regularly.
                  </p>
                </div>
                <div className="text mt-3 mt-xs-0 mt-md-0">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-heading">
                      <h6>Ragini Patil</h6>
                      <p className="">CSS, India Mart</p>
                    </div>
                    <div className="icon">
                      <img src={Icon} />
                    </div>
                  </div>
                  <p>
                    The ambulance providers on the healthtunnel list are top
                    notch, they were so helpful and timely. Always depend on
                    healthtunnel during emergency.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
