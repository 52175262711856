import React from 'react';
import '@assets/scss/pages/privacy-policy/inner-page.scss';
import { getValue } from '@utils/lodash';
// import showdown from 'showdown';

export default class Innerpage extends React.Component {
  render() {
    // var converter = new showdown.Converter();
    let desc = getValue(this.props, 'content', '');
    // converter.makeHtml(getValue(this.props, 'content', ''));
    return (
      <section className="inner-page-wrapper section-padding">
        <div className="custom-container">
          <p
            style={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{
              __html: desc
            }}
          />{' '}
        </div>
      </section>
    );
  }
}
