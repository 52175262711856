import React from 'react';
import '@assets/scss/pages/partner-with-us/banner.scss';
import { FormGroup, Label, Input } from 'reactstrap';
import { getValue } from '@utils/lodash';

export default class PartnerWithUs extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <section
        className="partner-with-us-banner-wrapper section-padding"
        style={{ backgroundImage: "url('/images/partner-wth-us/banner.png')" }}
      >
        <div className="custom-container d-flex justify-content-between flex-direction-column-tablet flex-direction-column-mobile">
          <div className="partner-with-us-banner-wrapper--meta">
            <h1 className="white-color mb-3 mt-md-5">
              Opportunity is everywhere
            </h1>
            <h3 className="light-font white-color">
              Get onboard India’s Largest Healthcare aggregator platform with
              All-in-one Health App and website Amplify your reach to the
              audience and cater to every home. Unlock new business
              opportunities and unlock borderless growth.
            </h3>
            <br></br>
            <h3 className="light-font white-color">
              Partnering with us gives you access to extensive reach to tier
              1 and tier 2 and tier 3 cities with very minimal efforts.
            </h3>
            <br></br>
            <h3 className="light-font white-color">
              If you are a service provider in our categories and not featured
              in our mobile app and website, please drop us a message and we
              will review your services and approve you to induct into our
              platform.
            </h3>
          </div>
          <div className="partner-with-us-banner-wrapper--form-wrapper">
            <h3 className="normal-font mb-3">Become a partner</h3>
            {/* <Form> */}
            <FormGroup>
              <Label for="fullname">Full Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="Enter Full Name"
                value={getValue(this.props, 'request.name', '')}
                onChange={this.props.handleChange}
              />
              <div className="form-error">
                {this.props.validator.message(
                  'Full Name',
                  this.props.request.name,
                  'required'
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="fullname">Company Name</Label>
              <Input
                type="text"
                name="company_name"
                id="company_name"
                placeholder="Enter Company Name"
                value={getValue(this.props, 'request.company_name', '')}
                onChange={this.props.handleChange}
              />
              <div className="form-error">
                {this.props.validator.message(
                  'Company Name',
                  this.props.request.company_name,
                  'required'
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="fullname">Work Email Address</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter Work Email Address"
                value={getValue(this.props, 'request.email', '')}
                onChange={this.props.handleChange}
              />
              <div className="form-error">
                {this.props.validator.message(
                  'Email Address',
                  this.props.request.email,
                  'required|email'
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="fullname">Phone Number</Label>
              <Input
                type="number"
                name="phone"
                id="phone"
                placeholder="Enter Phone Number"
                value={getValue(this.props, 'request.phone', '')}
                onChange={this.props.handleChange}
              />
              <div className="form-error">
                {this.props.validator.message(
                  'Phone Number',
                  this.props.request.phone,
                  'required|integer|size:10'
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="fullname">Comments</Label>
              <Input
                type="textarea"
                rows="3"
                name="comments"
                id="comments"
                placeholder="Enter Comments"
                value={getValue(this.props, 'request.comments', '')}
                onChange={this.props.handleChange}
              />
              <div className="form-error">
                {this.props.validator.message(
                  'Comments',
                  this.props.request.comments,
                  'required'
                )}
              </div>
            </FormGroup>
            <FormGroup className="mb-0 text-right">
              <button
                className="theme-button theme-primary-btn bold-font"
                onClick={this.props.handleSubmit}
              >
                Submit Now
              </button>
            </FormGroup>
            {/* </Form> */}
          </div>
        </div>
      </section>
    );
  }
}
